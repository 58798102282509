import { useDispatch, useSelector } from 'react-redux';

import * as Types from '../../Config/Types';
import * as Types_Rpa from '../../Config/Types_Rpa';

import _BaseActions from '../_BaseActions';

/**
 * element.removeEventListener("mousedown", handleMouseDown, true);  
 * Events: 
 *      https://developer.mozilla.org/en-US/docs/Web/Events/Creating_and_triggering_events
 *      
 *      With useEffect to unmount https://reactjs.org/docs/hooks-effect.html
 */
export default function RpaScriptHubActions() {
    const dispatch = useDispatch();
    const rpaScriptEditor = useSelector((state: Types.InitialState_TYPE) => state.rpaScriptEditor);

    const { updateAppState } = _BaseActions();


    const updateCommands = (envelope: Types_Rpa.Rpa_Hub_ScriptCommandsUpdate_Message_TYPE) => {
        dispatch(updateAppState('rpaScriptEditor', {
            ...rpaScriptEditor,
            commandsSections: envelope.sections && envelope.sections.length > 0 ? envelope.sections : []
            // Reset
        }));
    };

    const logMessage = (envelope: Types_Rpa.Rpa_Hub_LogMessage_TYPE) => {
        var event = new CustomEvent(envelope.messageName, { detail: envelope });
        document.dispatchEvent(event);
    };

    return {
        logMessage,
        updateCommands
    };
}