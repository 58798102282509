import { Action_TYPE, ACTIONS } from '../Config/Types';
import { initialState } from './initialState';

const appReducer = (state = initialState(), action: Action_TYPE) => {
  switch (action.type) {
    case ACTIONS.SCREEN_LOCK_REQUEST: {
      return { ...state, spinnigCounter: ++state.spinnigCounter };
    }
    case ACTIONS.SCREEN_UNLOCK_REQUEST: {
      return { ...state, spinnigCounter: --state.spinnigCounter };
    }
    case ACTIONS.UPDATE_APP_STATE: {
      const { name, value } = action.payload;
      const newState = { ...state, [name]: value };
      return newState;
    }
    case ACTIONS.UPDATE_BULK_APP_STATE: {
      const { values } = action.payload;
      const newState = { ...state, ...values };
      return newState;
    }
    default:
      return state;
  }
};

export default appReducer;