import { Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router';

import Page from '../../../Layout/FactoryPage';
import _BaseActions from '../../../actions/_BaseActions';

import useChangePasswordPage from './useChangePasswordPage';


function ChangePasswordPage() {
    const navigate = useNavigate();
    const { form, onChange, onClickChangePassword } = useChangePasswordPage(navigate);
    const { getLabel } = _BaseActions();

    return (
        <Page>
            <Row>
                <Col md={12} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }} style={{ backgroundColor: 'white' }} >
                    <Modal.Header>
                        <Col>
                            <Modal.Title>{getLabel('changePasswordPage.title')}</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend className='inputCustom-FD'>
                                <InputGroup.Text style={{ minWidth: 200 }}>{getLabel('changePasswordPage.oldPassword')}</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control name='oldPassword' type='password' autoComplete="off" value={form.oldPassword} onChange={onChange} />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend className='inputCustom-FD'>
                                <InputGroup.Text style={{ minWidth: 200 }}>{getLabel('changePasswordPage.newPassword')}</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control name='newPassword' type='password' autoComplete="off" value={form.newPassword} onChange={onChange} />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend className='inputCustom-FD'>
                                <InputGroup.Text style={{ minWidth: 200 }}>{getLabel('changePasswordPage.renewPassword')}</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control name='renewPassword' type='password' autoComplete="off" value={form.renewPassword} onChange={onChange} />
                        </InputGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="e-control e-btn e-primary" onClick={(e) => onClickChangePassword(e)}>{getLabel('changePasswordPage.confirm_button')}</button>
                    </Modal.Footer>
                </Col>
            </Row>
        </Page>
    );
}

export default ChangePasswordPage;
