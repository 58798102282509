import { Utils } from '@firedesktop/react-base';
import { ConnectorModel, NodeConstraints, NodeModel, PageSettingsModel } from '@syncfusion/ej2-react-diagrams';
import _ from 'lodash';
import FileSaver from 'file-saver';
import { useDispatch, useSelector } from 'react-redux';

import { DocumentEditor_Object } from '../../Components/Designers/DocumentEditor';

import { createInstaceFieldAmend, createValidatorCfg, initialState } from '../../Redux/initialState';
import * as Enums from '../../Config/Enums';
import * as Types from '../../Config/Types';
import * as Types_Rec from '../../Config/Types_Rec';
import * as Constants from '../../Config/Constants';

import _BaseActions from '../_BaseActions';
import AuthenticationActions from '../Authentication/AuthenticationActions';


const Editor_Constants = Constants.Recognition_DocumentEditor();

export default function BundleEditorActions() {
    const authentication = useSelector((state: Types.InitialState_TYPE) => state.authentication);
    const configuration = useSelector((state: Types.InitialState_TYPE) => state.configuration);
    const bundleEditor = useSelector((state: Types.InitialState_TYPE) => state.bundleEditor);

    const dispatch = useDispatch();

    const { fromBeError, getLabel, lockScreenRequest, toast, unLockScreenRequest, updateAppState } = _BaseActions();
    const _initState = initialState();

    const { actionOn_401, actionOn_403 } = AuthenticationActions();
    const runner = new Utils.Fetch.FetchWrapper(undefined, undefined, actionOn_401, actionOn_403);


    const instanceBundle_export = async (instanceBundleId: number) => {
        const url = `${configuration.api?.recognition}InstanceBundle/${instanceBundleId}/export`;

        dispatch(lockScreenRequest());
        runner.get(url, configuration.applicationName, authentication.token,
            (status, error) => fromBeError(status, error, () => {
                toast({
                    title: getLabel('common.toaster.title.error'),
                    content: getLabel('common.api.errors.instanceBundle.export'),
                    type: 'Error'
                });
            }), false, true).then((zipFile: File) => {
                dispatch(unLockScreenRequest());
                FileSaver.saveAs(zipFile);
            }).catch((errorMessage: any) => {
                dispatch(unLockScreenRequest());
                console.error(errorMessage);
            });
    };

    const deleteInstanceBundle = async (instanceBundleId: number, searchText: string): Promise<boolean> => {
        const url = `${configuration.api?.recognition}InstanceBundle/${instanceBundleId}`;

        dispatch(lockScreenRequest());
        return await runner.delete(url, configuration.applicationName, authentication.token,
            (status, error) => fromBeError(status, error, () => {
                toast({
                    title: getLabel('common.toaster.title.error'),
                    content: getLabel('common.api.errors.instanceBundle.delete'),
                    type: 'Error'
                });
            })).then((txt: Types.Dump_TYPE) => {
                dispatch(unLockScreenRequest());
                toast({
                    title: getLabel('common.toaster.title.info'),
                    content: getLabel('common.api.success.instanceBundle.delete'),
                    type: 'Success'
                });
                loadInstanceBundles(bundleEditor.instanceBundles.itemsPerPage, bundleEditor.instanceBundles.page, bundleEditor.instanceBundles.sort, searchText);
                return true;
            }).catch((errorMessage: any) => {
                dispatch(unLockScreenRequest());
                console.error(errorMessage);
                return false;
            });
    };

    const deleteInstanceBundleInstance = async (instanceBundleInstanceId: number, searchText: string, status?: string, template?: string): Promise<boolean> => {
        const url = `${configuration.api?.recognition}Instance/${instanceBundleInstanceId}`;

        dispatch(lockScreenRequest());
        return await runner.delete(url, configuration.applicationName, authentication.token,
            (status, error) => fromBeError(status, error, () => {
                toast({
                    title: getLabel('common.toaster.title.error'),
                    content: getLabel('common.api.errors.instance.delete'),
                    type: 'Error'
                });
            })).then((txt: Types.Dump_TYPE) => {
                dispatch(unLockScreenRequest());
                toast({
                    title: getLabel('common.toaster.title.info'),
                    content: getLabel('common.api.success.instance.delete'),
                    type: 'Success'
                });
                loadInstanceBundleInstances(bundleEditor.selectedInstanceBundle.id, bundleEditor.instanceBundleInstances.itemsPerPage,
                    bundleEditor.instanceBundleInstances.page, bundleEditor.instanceBundleInstances.sort, searchText, status, template);
                return true;
            }).catch((errorMessage: any) => {
                dispatch(unLockScreenRequest());
                console.error(errorMessage);
                return false;
            });
    };

    const loadDumpDocumentAdvanced = async (instanceId: number): Promise<Types_Rec.Rec_DumpDocument_TYPE | undefined> => {
        const url = `${configuration.api?.recognition}Instance/${instanceId}/DumpDocumentAdvanced`;

        dispatch(lockScreenRequest());
        return await runner.post(url, configuration.applicationName, authentication.token).then((dump: Types_Rec.Rec_DumpDocument_TYPE) => {
            dispatch(unLockScreenRequest());
            return dump;
        }).catch((errorMessage: any) => {
            dispatch(unLockScreenRequest());
            console.error(errorMessage);
            return undefined;
        });
    };

    const loadDumpDocument = async (instanceId: number, reprocess: boolean, merge: boolean, normal: boolean, calcSpaces: boolean): Promise<string> => {
        const url = `${configuration.api?.recognition}Instance/${instanceId}/DumpDocument`;

        dispatch(lockScreenRequest());
        return await runner.post(url, configuration.applicationName, authentication.token, undefined, { merge, reprocess, normal, calcSpaces }).then((txt: Types.Dump_TYPE) => {
            dispatch(unLockScreenRequest());
            if (txt && txt.dump)
                return txt.dump;
            return '';
        }).catch((errorMessage: any) => {
            dispatch(unLockScreenRequest());
            console.error(errorMessage);
            return '';
        });
    };

    const loadDumpField = async (instanceId: number, fieldId: number, reprocess: boolean, merge: boolean, normal: boolean, calcSpaces: boolean): Promise<string> => {
        const url = `${configuration.api?.recognition}Instance/${instanceId}/DumpField/${fieldId}`;

        dispatch(lockScreenRequest());
        return await runner.post(url, configuration.applicationName, authentication.token, undefined, { merge, reprocess, normal, calcSpaces }).then((txt: Types.Dump_TYPE) => {
            dispatch(unLockScreenRequest());
            if (txt && txt.dump)
                return txt.dump;
            return '';
        }).catch((errorMessage: any) => {
            dispatch(unLockScreenRequest());
            console.error(errorMessage);
            return '';
        });
    };

    const onAddField = async () => {
        if (bundleEditor.documentEditorObject && bundleEditor.documentEditorObject.activePage) {
            console.log('BundleEditoActions - onAddField');
            const field = await addInstanceField(bundleEditor.selectedInstanceBundleInstance.id, bundleEditor.documentEditorObject.activePage);
            if (!field) {
                toast({
                    title: getLabel('common.toaster.title.error'),
                    content: getLabel('common.api.errors.instance.addInstance_Field'),
                    type: 'Error'
                });
                return;
            }

            // Add the new one
            bundleEditor.selected_instace_page_filled.instanceFields.items.push(field);

            // Regenerate model
            const documentEditorObject = toSynfusionDocumentEditorObject(bundleEditor.selected_instace_page_filled, false);

            // Update state
            dispatch(updateAppState('bundleEditor', {
                ...bundleEditor,
                // Reset
                ...{
                    documentEditorObject
                }
            }));
        }
    };

    const onDownLoadJsonInstance = async () => {
        const dto = _.cloneDeep(bundleEditor.selected_instace_page_filled.instanceDetails);
        dto.instanceFields = _.cloneDeep(bundleEditor.selected_instace_page_filled.instanceFields.items);

        const json = JSON.stringify(dto);
        const blob = new Blob([json], { type: 'data:text/json;charset=utf-8,' });
        FileSaver.saveAs(blob, `Instace_${bundleEditor.selected_instace_page_filled.instanceDetails.id}_${bundleEditor.selected_instace_page_filled.instanceDetails.createdOn.substr(0, 10)}.json`);
    };

    const selectInstanceBundle = async (selectedInstanceBundle?: Types.InstanceBundle_TYPE) => {
        if (!selectedInstanceBundle) {
            dispatch(updateAppState('bundleEditor', {
                ...bundleEditor,
                // Reset
                selectedInstanceBundle: _initState.bundleEditor.selectedInstanceBundle,
                documentEditorObject: _initState.bundleEditor.documentEditorObject,
                instanceBundleInstances: _initState.bundleEditor.instanceBundleInstances,
                instanceBundleTemplates: _initState.bundleEditor.instanceBundleTemplates,
                selectedInstanceBundleInstance: _initState.bundleEditor.selectedInstanceBundleInstance,
                selected_instace_page_filled: _initState.bundleEditor.selected_instace_page_filled,
                selectedInstanceField_Id: _initState.bundleEditor.selectedInstanceField_Id
            }));

            return;
        }

        const arr = await Promise.all(
            [
                getInstanceBundleInstances(selectedInstanceBundle.id, bundleEditor.instanceBundleInstances.itemsPerPage, 1, undefined, '', undefined),
                getInstanceBundleTemplates(selectedInstanceBundle.id)
            ]);

        const instanceBundleInstances = arr[0];
        const instanceBundleTemplates = arr[1];

        dispatch(updateAppState('bundleEditor', {
            ...bundleEditor,
            instanceBundleInstances,
            selectedInstanceBundle,
            instanceBundleTemplates,
            // Reset
            documentEditorObject: _initState.bundleEditor.documentEditorObject,
            selectedInstanceBundleInstance: _initState.bundleEditor.selectedInstanceBundleInstance,
            selected_instace_page_filled: _initState.bundleEditor.selected_instace_page_filled,
            selectedInstanceField_Id: _initState.bundleEditor.selectedInstanceField_Id
        }));
    };

    const selectInstanceBundleInstance = async (
        page: number,
        selectedInstanceBundleInstance?: Types.InstanceBundleInstance_TYPE,
        selectedInstanceField_Id?: number) => {

        console.log(`BundleEditorActions - selectInstanceBundleInstance ${page}`, selectedInstanceBundleInstance);
        if (!selectedInstanceBundleInstance) {
            dispatch(updateAppState('bundleEditor', {
                ...bundleEditor,
                // Reset
                documentEditorObject: _initState.bundleEditor.documentEditorObject,
                selectedInstanceBundleInstance: _initState.bundleEditor.selectedInstanceBundleInstance,
                selected_instace_page_filled: _initState.bundleEditor.selected_instace_page_filled,
                selectedInstanceField_Id: _initState.bundleEditor.selectedInstanceField_Id
            }));
            return;
        }

        // 1. Get Instance details
        const instanceDetails = await getInstanceDatails(selectedInstanceBundleInstance.id);
        if (!instanceDetails) {
            toast({
                title: getLabel('common.toaster.title.error'),
                content: getLabel('common.api.errors.instance.load_instance'),
                type: 'Error'
            });
            return;
        }
        // 2. Get page details
        // 3. Get the fields properties
        // 4. Get templates for instance
        const arr = await Promise.all(
            [
                getInstanceProcessedPageDetails(selectedInstanceBundleInstance.id, page - 1),
                getInstancePageFieldsWithAreas(selectedInstanceBundleInstance.id, 1000, 1, page - 1)
            ]);
        const selected_instace_page_filled = {
            instanceDetails: instanceDetails,
            instanceFields: arr[1],
            instancePageDetails: arr[0],
            instanceTemplates: _.cloneDeep(bundleEditor.instanceBundleTemplates)
        } as Types.Instance_Page_Filled_TYPE;

        if (!selected_instace_page_filled.instancePageDetails || !selected_instace_page_filled.instanceFields) {
            toast({
                title: getLabel('common.toaster.title.warning'),
                content: getLabel('common.api.errors.instance.load_instance'),
                type: 'Warning'
            });
        }

        // Clean Anchor value
        if (selected_instace_page_filled.instanceFields && selected_instace_page_filled.instanceFields.items) {
            for (let index = 0; index < selected_instace_page_filled.instanceFields.items.length; index++) {
                const instance_field = selected_instace_page_filled.instanceFields.items[index];
                const anchorArea = instance_field.areaViewInfo ? instance_field.areaViewInfo.anchorArea : undefined;
                if (anchorArea && (anchorArea.rectangle || anchorArea.resultRectangle)) {

                    if (anchorArea.rectangle && anchorArea.rectangle.width === 0) {
                        if (anchorArea.resultRectangle && anchorArea.resultRectangle.width > 0) {
                            anchorArea.rectangle.x = anchorArea.resultRectangle.x - 15;
                            anchorArea.rectangle.y = anchorArea.resultRectangle.y - 15;
                            anchorArea.rectangle.width = anchorArea.resultRectangle.width + 30;
                            anchorArea.rectangle.height = anchorArea.resultRectangle.height + 30;
                        }
                    }
                    else {
                        if (anchorArea.resultRectangle && anchorArea.resultRectangle.width > 0)
                            anchorArea.rectangle = _.cloneDeep(anchorArea.resultRectangle);
                    }
                }
            }
        }

        // 3. create object for des: _initState.bundleEditor.instance
        const documentEditorObject = toSynfusionDocumentEditorObject(selected_instace_page_filled, true);

        // 4. Update state
        if (!selectedInstanceField_Id) {
            selectedInstanceField_Id = _initState.bundleEditor.selectedInstanceField_Id;
            console.log(`selectedInstanceField_Id: ${selectedInstanceField_Id}`);
        }

        dispatch(updateAppState('bundleEditor', {
            ...bundleEditor,
            // Reset
            ...{
                documentEditorObject,
                selectedInstanceBundleInstance: selectedInstanceBundleInstance,
                selected_instace_page_filled,
                selectedInstanceField_Id
            }
        }));
    };

    const selectInstanceField = async (selectedInstanceField_Id?: number) => {
        console.log(`BundleEditoActions - selectInstanceField selectedInstanceField_Id: ${selectedInstanceField_Id}`);
        dispatch(updateAppState('bundleEditor', {
            ...bundleEditor,
            selectedInstanceField_Id
        }));
    };

    const loadInstanceBundles = async (pageSize?: number, pageIndex?: number, sort?: string, search?: string) => {
        let url = `${configuration.api?.recognition}InstanceBundle?`;

        if (!_.isNil(pageSize))
            url += `pageSize=${pageSize}&`;

        if (!_.isNil(pageIndex)) {
            url += `pageIndex=${pageIndex}&`;
        }

        if (!_.isNil(sort))
            url += `pageIndex=${sort}&`;

        if (!_.isNil(search))
            url += `search=${search}&`;

        dispatch(lockScreenRequest());
        return runner.get(url, configuration.applicationName, authentication.token).then((instanceBundles: Types.InstanceBundle_Search_Type) => {
            dispatch(unLockScreenRequest());
            dispatch(updateAppState('bundleEditor', {
                ...bundleEditor,
                instanceBundles,
                // Reset
                documentEditorObject: _initState.bundleEditor.documentEditorObject,
                instanceBundleInstances: _initState.bundleEditor.instanceBundleInstances,
                selectedInstanceBundle: _initState.bundleEditor.selectedInstanceBundle,
                selectedInstanceBundleInstance: _initState.bundleEditor.selectedInstanceBundleInstance,
                selected_instace_page_filled: _initState.bundleEditor.selected_instace_page_filled,
                selectedInstanceField_Id: _initState.bundleEditor.selectedInstanceField_Id
            }));
        }).catch((errorMessage: any) => {
            dispatch(unLockScreenRequest());
            console.error(errorMessage);
            toast({
                title: getLabel('common.toaster.title.error'),
                content: getLabel('common.api.errors.instanceBundle.load_instanceBundles'),
                type: 'Error'
            });
        });
    };

    const getInstanceBundleInstances = async (instanceBundleId: number, pageSize: number, pageIndex: number, sort?: string, search?: string, status?: string, template?: string)
        : Promise<Types.InstanceBundleInstance_Search_Type> => {
        let url = `${configuration.api?.recognition}InstanceBundle/${instanceBundleId}/Instace?`;

        url += `pageSize=${pageSize}&pageIndex=${pageIndex}&`;

        if (!_.isNil(sort))
            url += `sort=${sort}&`;

        if (!_.isNil(search))
            url += `search=${search}&`;

        if (!_.isNil(status) || status === 0)
            url += `matchingStatus=${status}&`;

        if (!_.isNil(template) || template === 0)
            url += `template=${template}&`;

        dispatch(lockScreenRequest());
        return await runner.get(url, configuration.applicationName, authentication.token).then((instanceBundleInstances: Types.InstanceBundleInstance_Search_Type) => {
            dispatch(unLockScreenRequest());
            return instanceBundleInstances;
        }).catch((errorMessage: any) => {
            dispatch(unLockScreenRequest());
            console.error(errorMessage);
            toast({
                title: getLabel('common.toaster.title.error'),
                content: getLabel('common.api.errors.instanceBundle.load_instanceBundleInstances'),
                type: 'Error'
            });
            return {
                items: [],
                page: 1,
                itemsPerPage: pageSize,
                totalItems: 0,
                sort: sort
            } as Types.InstanceBundleInstance_Search_Type;
        });
    };

    const loadInstanceBundleInstances = async (instanceBundleId: number, pageSize: number, pageIndex: number, sort?: string, search?: string, status?: string, template?: string) => {
        const instanceBundleInstances = await getInstanceBundleInstances(instanceBundleId, pageSize, pageIndex, sort, search, status, template);

        const newBundleEditor = {
            ...bundleEditor,
            instanceBundleInstances,
            // Reset
            documentEditorObject: _initState.bundleEditor.documentEditorObject,
            selected_instace_page_filled: _initState.bundleEditor.selected_instace_page_filled,
            selectedInstanceBundleInstance: _initState.bundleEditor.selectedInstanceBundleInstance,
            selectedInstanceField_Id: _initState.bundleEditor.selectedInstanceField_Id
        };
        dispatch(updateAppState('bundleEditor', newBundleEditor));
    };

    const onInstance_Field_Process = async (field_id: number) => {
        const targetField_Clone = _.cloneDeep(bundleEditor.selected_instace_page_filled.instanceFields.items.filter(x => { return x.id === field_id; })[0]);

        const url = `${configuration.api?.recognition}Instance/${targetField_Clone.instanceId}/processfield/${field_id}`;

        dispatch(lockScreenRequest());
        return await runner.post(url, configuration.applicationName, authentication.token).then((instanceField: Types.Instance_Field_TYPE) => {
            dispatch(unLockScreenRequest());
            // We do not want to resave on BE...
            onInstance_Field_Changed(instanceField, true, false, false);
            toast({
                title: getLabel('common.toaster.title.info'),
                content: getLabel('common.api.success.instance.processField'),
                type: 'Success'
            });
        }).catch((errorMessage: any) => {
            dispatch(unLockScreenRequest());
            console.error(`Error when run Process Field: ${field_id}, message ${errorMessage}`);
            toast({
                title: getLabel('common.toaster.title.error'),
                content: getLabel('common.api.errors.instance.processField'),
                type: 'Error'
            });
        });
    };

    const onInstance_Field_Persist = async (field_id: number) => {
        console.log(`BundleEditoActions - onInstance_Field_Persist field_id: ${field_id}`);
        const targetField_Clone = _.cloneDeep(bundleEditor.selected_instace_page_filled.instanceFields.items.filter(x => { return x.id === field_id; })[0]);
        // We want to save on BE...
        onInstance_Field_Changed(targetField_Clone, true, true, false);
    };

    const onInstance_Field_Amend_Changed = async (field_id: number, value: string) => {
        console.log(`BundleEditoActions - onInstance_Field_Amend_Changed field_id: ${field_id}, value: ${value}`);
        if (bundleEditor && bundleEditor.selected_instace_page_filled && bundleEditor.selected_instace_page_filled.instanceFields.items) {
            const targetField_Clone = _.cloneDeep(bundleEditor.selected_instace_page_filled.instanceFields.items.filter(x => { return x.id === field_id; })[0]);

            // Create/Update Amend
            let amend = targetField_Clone.instanceFieldAmends && targetField_Clone.instanceFieldAmends.length ? targetField_Clone.instanceFieldAmends[0] : undefined;
            if (_.isNil(amend)) {
                amend = createInstaceFieldAmend(targetField_Clone, value);
                targetField_Clone.instanceFieldAmends = [];
                targetField_Clone.instanceFieldAmends.push(amend);
            }
            amend.value = value;
            amend._toBeSaved = true;

            // Update State, We do not want to save on BE, because there's a button save close to the value...
            onInstance_Field_Changed(targetField_Clone, true, false, false);
        }
    };

    const onInstance_Field_Amend_Save = async (field_id: number) => {
        console.log(`BundleEditoActions - onInstance_Field_Amend_Save field_id: ${field_id}`);
        const targetField_Clone = _.cloneDeep(bundleEditor.selected_instace_page_filled.instanceFields.items.filter(x => { return x.id === field_id; })[0]);
        const amend = targetField_Clone.instanceFieldAmends && targetField_Clone.instanceFieldAmends.length ? targetField_Clone.instanceFieldAmends[0] : undefined;
        if (_.isNil(amend))
            return;

        const url = `${configuration.api?.recognition}Instance/${targetField_Clone.instanceId}/Amend`;

        dispatch(lockScreenRequest());
        return await runner.post(url, configuration.applicationName, authentication.token,
            (status, error) => fromBeError(status, error, () => {
                toast({
                    title: getLabel('common.toaster.title.error'),
                    content: getLabel('common.api.errors.instance.updateAmend'),
                    type: 'Error'
                });
            }), amend).then((amend_BE: Types.Instance_Field_Amend_TYPE) => {
                dispatch(unLockScreenRequest());
                if (_.isNil(amend.id)) {
                    targetField_Clone.instanceFieldAmends = [];
                    targetField_Clone.instanceFieldAmends.push(amend_BE);
                } else
                    amend._toBeSaved = false;

                // We do not want to resave on BE...
                onInstance_Field_Changed(targetField_Clone, true, false, false);
                toast({
                    title: getLabel('common.toaster.title.info'),
                    content: getLabel('common.api.success.instance.updateAmend'),
                    type: 'Success'
                });
            }).catch((errorMessage: any) => {
                dispatch(unLockScreenRequest());
                console.error(`Error when Saving Amend for this field: ${field_id}, message ${errorMessage}`);
            });
    };

    const onInstance_Field_Configuration_PropertyChange: Types.OnInstance_Field_Configuration_Property_Change = (name: string, value: any, persist: boolean) => {
        console.log(`BundleEditoActions - onInstance_Field_Configuration_PropertyChange name: ${name}, value: ${value}, persist: ${persist}`);
        if (bundleEditor && bundleEditor.selected_instace_page_filled && bundleEditor.selected_instace_page_filled.instanceFields.items) {
            const targetField_Clone = _.cloneDeep(bundleEditor.selected_instace_page_filled.instanceFields.items.filter(x => { return x.id === bundleEditor.selectedInstanceField_Id; })[0]);

            let forceRefresh = false;
            const splitted = name.split('.');

            if (splitted.length === 4 && splitted[0] === 'areaViewInfo' && targetField_Clone.areaViewInfo && targetField_Clone.areaViewInfo.fieldArea) {
                if (splitted[1] === 'fieldArea' && splitted[2] === 'rectangle' && (splitted[3] === 'x' || splitted[3] === 'y' || splitted[3] === 'width' || splitted[3] === 'height')) {
                    if (!targetField_Clone.areaViewInfo.fieldArea.rectangle)
                        targetField_Clone.areaViewInfo.fieldArea.rectangle = { height: 0, width: 0, x: 0, y: 0 };

                    let appValue = _.toNumber(value);
                    if (isNaN(appValue) || appValue < 0)
                        appValue = 0;

                    targetField_Clone.areaViewInfo.fieldArea.rectangle[splitted[3]] = appValue;
                }
            }
            // ********************************************************************************************************************************
            // fieldConfiguration
            // ********************************************************************************************************************************
            // First level Field
            else if (splitted.length === 1) {
                if (name === 'fieldName' || name === 'inFieldConstValue' || name === 'keyString' || name === 'script')
                    targetField_Clone.fieldConfiguration[name] = value;
                else if (name === 'outType') {
                    forceRefresh = false;
                    targetField_Clone.fieldConfiguration[name] = parseInt(value, 10);
                    if (targetField_Clone.fieldConfiguration.validatorCfg)
                        targetField_Clone.fieldConfiguration.validatorCfg.validatorType = '';
                } else if (name === 'fieldType' || name === 'fieldContentType') {
                    forceRefresh = false;
                    targetField_Clone.fieldConfiguration[name] = parseInt(value, 10);
                }
                else if (name === 'wholePage' || name === 'allPages' || name === 'invertArithmetic' || name === 'isMarker')
                    targetField_Clone.fieldConfiguration[name] = value;
            }
            else if (splitted.length === 2 && splitted[0] === 'validatorCfg') {
                if (!targetField_Clone.fieldConfiguration.validatorCfg)
                    targetField_Clone.fieldConfiguration.validatorCfg = createValidatorCfg();

                if (splitted[1] === 'expression' || splitted[1] === 'validatorType')
                    targetField_Clone.fieldConfiguration.validatorCfg[splitted[1]] = value;
                if (splitted[1] === 'collateMatchResults' || splitted[1] === 'collateResults')
                    targetField_Clone.fieldConfiguration.validatorCfg[splitted[1]] = value;
            }
            // Anchor
            else if (splitted.length === 3 && splitted[1] === 'validatorCfg') {
                if (!targetField_Clone.fieldConfiguration.anchorCfg)
                    targetField_Clone.fieldConfiguration.anchorCfg = {} as Types.AnchorCfg_TYPE;

                if (!targetField_Clone.fieldConfiguration.anchorCfg.validatorCfg)
                    targetField_Clone.fieldConfiguration.anchorCfg.validatorCfg = createValidatorCfg();

                if (splitted[2] === 'expression' || splitted[2] === 'validatorType')
                    targetField_Clone.fieldConfiguration.anchorCfg.validatorCfg[splitted[2]] = value;
                if (splitted[2] === 'collateMatchResults' || splitted[2] === 'collateResults')
                    targetField_Clone.fieldConfiguration.anchorCfg.validatorCfg[splitted[2]] = value;
            }
            else {
                const propertyName = splitted[1];
                if (!targetField_Clone.fieldConfiguration.anchorCfg)
                    targetField_Clone.fieldConfiguration.anchorCfg = {} as Types.AnchorCfg_TYPE;

                if (propertyName === 'keyString')
                    targetField_Clone.fieldConfiguration.anchorCfg[propertyName] = value;
                else if (propertyName === 'outType') {
                    targetField_Clone.fieldConfiguration.anchorCfg[propertyName] = parseInt(value, 10);

                    if (targetField_Clone.fieldConfiguration.anchorCfg.validatorCfg)
                        targetField_Clone.fieldConfiguration.anchorCfg.validatorCfg.validatorType = '';

                } else if (propertyName === 'fieldType')
                    targetField_Clone.fieldConfiguration.anchorCfg[propertyName] = parseInt(value, 10);
                else if (propertyName === 'wholePage' || propertyName === 'allPages')
                    targetField_Clone.fieldConfiguration.anchorCfg[propertyName] = value;
            }

            // Update State, We save on BE  based on persist value.
            onInstance_Field_Changed(targetField_Clone, true, persist, forceRefresh);
        }
    };

    const onNodeMoved = (id: string, x: number, y: number, selectNode: boolean) => {
        console.log(`BundleEditoActions - onNodeMoved id: ${id}, x: ${x}, y: ${y}`);
        let nodeId = -1;
        let targetField_Clone: Types.Instance_Field_TYPE | undefined;
        let target: Types.Rectangle_TYPE | undefined;
        if (id.indexOf(Editor_Constants.Anchor_Rectangle_Node_Partial_Name) > -1) {
            nodeId = parseInt(id.replace(Editor_Constants.Anchor_Rectangle_Node_Partial_Name, ''), 10);
            targetField_Clone = _.cloneDeep(bundleEditor.selected_instace_page_filled.instanceFields.items.filter(x => { return x.id === nodeId; })[0]);
            if (targetField_Clone && targetField_Clone.areaViewInfo && targetField_Clone.areaViewInfo.anchorArea)
                target = targetField_Clone.areaViewInfo.anchorArea.rectangle;
        }
        else if (id.indexOf(Editor_Constants.Rectangle_Node_Partial_Name) > -1) {
            nodeId = parseInt(id.replace(Editor_Constants.Rectangle_Node_Partial_Name, ''), 10);
            targetField_Clone = _.cloneDeep(bundleEditor.selected_instace_page_filled.instanceFields.items.filter(x => { return x.id === nodeId; })[0]);
            if (targetField_Clone && targetField_Clone.areaViewInfo && targetField_Clone.areaViewInfo.fieldArea)
                target = targetField_Clone.areaViewInfo.fieldArea.rectangle;
        }

        // Set the propesties only if they are different
        if (targetField_Clone) {
            if (target && (target.x !== x || target.y !== y)) {
                target.x = x;
                target.y = y;
                // We want to save on BE.
                onInstance_Field_Changed(targetField_Clone, selectNode, true, false);
            }
            else
                // We do not want to save a node selection
                onInstance_Field_Changed(targetField_Clone, selectNode, false, false);
        }
    };

    const onNodeSizeChange = (id: string, width: number, height: number, customNodeProperties?: object) => {
        console.log(`BundleEditoActions - onNodeSizeChange id: ${id}, width: ${width}, height: ${height}`);
        let nodeId = -1;
        let targetField_Clone: Types.Instance_Field_TYPE | undefined;
        let target: Types.Rectangle_TYPE | undefined;
        if (id.indexOf(Editor_Constants.Anchor_Rectangle_Node_Partial_Name) > -1) {
            nodeId = parseInt(id.replace(Editor_Constants.Anchor_Rectangle_Node_Partial_Name, ''), 10);
            targetField_Clone = _.cloneDeep(bundleEditor.selected_instace_page_filled.instanceFields.items.filter(x => { return x.id === nodeId; })[0]);
            if (targetField_Clone && targetField_Clone.areaViewInfo && targetField_Clone.areaViewInfo.anchorArea)
                target = targetField_Clone.areaViewInfo.anchorArea.rectangle;
        }
        else if (id.indexOf(Editor_Constants.Rectangle_Node_Partial_Name) > -1) {
            nodeId = parseInt(id.replace(Editor_Constants.Rectangle_Node_Partial_Name, ''), 10);
            targetField_Clone = _.cloneDeep(bundleEditor.selected_instace_page_filled.instanceFields.items.filter(x => { return x.id === nodeId; })[0]);
            if (targetField_Clone && targetField_Clone.areaViewInfo && targetField_Clone.areaViewInfo.fieldArea)
                target = targetField_Clone.areaViewInfo.fieldArea.rectangle;
        }

        // 1. Get the righr node
        if (targetField_Clone && target) {
            target.width = width;
            target.height = height;

            // We want to save on BE.
            onInstance_Field_Changed(targetField_Clone, true, true, false);
        }
    };

    const runProcessInstanceBundle = async (instanceBundleId: number, processingOptions: Types.ProcessingOptions): Promise<void> => {
        const url = `${configuration.api?.recognition}InstanceBundle/${instanceBundleId}/process`;

        dispatch(lockScreenRequest());
        return await runner.post(url, configuration.applicationName, authentication.token,
            (status, error) => fromBeError(status, error, () => {
                toast({
                    title: getLabel('common.toaster.title.error'),
                    content: getLabel('common.api.errors.instanceBundle.runProcessBundle'),
                    type: 'Error'
                });
            }), { processingOptions }).then(() => {
                dispatch(unLockScreenRequest());
                toast({
                    title: getLabel('common.toaster.title.info'),
                    content: getLabel('common.api.success.instanceBundle.runProcessBundle'),
                    type: 'Success'
                });
            }).catch((errorMessage: any) => {
                dispatch(unLockScreenRequest());
                console.error(`Error when runProcessBundle, message ${errorMessage}`);
            });
    };

    const runProcessInstance = async (instanceId: number, processingOptions: Types.ProcessingOptions): Promise<void> => {
        const url = `${configuration.api?.recognition}Instance/${instanceId}/process`;

        dispatch(lockScreenRequest());
        return await runner.post(url, configuration.applicationName, authentication.token,
            (status, error) => fromBeError(status, error, () => {
                toast({
                    title: getLabel('common.toaster.title.error'),
                    content: getLabel('common.api.errors.instance.runProcessInstance'),
                    type: 'Error'
                });
            }), processingOptions).then(() => {
                dispatch(unLockScreenRequest());
                toast({
                    title: getLabel('common.toaster.title.info'),
                    content: getLabel('common.api.success.instance.runProcessInstance'),
                    type: 'Success'
                });
            }).catch((errorMessage: any) => {
                dispatch(unLockScreenRequest());
                console.error(`Error when runProcessInstance, message ${errorMessage}`);
            });
    };

    const runProcessInstancesAll = async (processingOptions: Types.ProcessingOptions, bundleId: number, searchText?: string, matchingStatus?: number) => {
        const url = `${configuration.api?.recognition}InstanceBundle/${bundleId}/process`;

        dispatch(lockScreenRequest());
        return await runner.post(url, configuration.applicationName, authentication.token,
            (status, error) => fromBeError(status, error, () => {
                toast({
                    title: getLabel('common.toaster.title.error'),
                    content: getLabel('common.api.errors.instance.runProcessInstanceAll'),
                    type: 'Error'
                });
            }), { processingOptions, searchText, matchingStatus }).then(() => {
                dispatch(unLockScreenRequest());
                toast({
                    title: getLabel('common.toaster.title.info'),
                    content: getLabel('common.api.success.instance.runProcessInstanceAll'),
                    type: 'Success'
                });
            }).catch((errorMessage: any) => {
                dispatch(unLockScreenRequest());
                console.error(`Error when runnning Process Instance All, message ${errorMessage}`);
            });
    };

    const runProcessInstanceRelated = async (instanceId: number, processingOptions: Types.ProcessingOptions): Promise<void> => {
        const url = `${configuration.api?.recognition}Instance/${instanceId}/processrelated`;

        dispatch(lockScreenRequest());
        return await runner.post(url, configuration.applicationName, authentication.token,
            (status, error) => fromBeError(status, error, () => {
                toast({
                    title: getLabel('common.toaster.title.error'),
                    content: getLabel('common.api.errors.instance.runProcessInstanceRelated'),
                    type: 'Error'
                });
            }), processingOptions).then(() => {
                dispatch(unLockScreenRequest());
                toast({
                    title: getLabel('common.toaster.title.info'),
                    content: getLabel('common.api.success.instance.runProcessInstanceRelated'),
                    type: 'Success'
                });
            }).catch((errorMessage: any) => {
                dispatch(unLockScreenRequest());
                console.error(`Error when runProcessInstance, message ${errorMessage}`);
            });
    };

    const saveTemplateFromInstance = async (): Promise<void> => {
        if (bundleEditor.selectedInstanceBundleInstance.id < 0)
            return;

        const url = `${configuration.api?.recognition}Instance/${bundleEditor.selectedInstanceBundleInstance.id}/saveTemplate`;

        const dto = _.cloneDeep(bundleEditor.selected_instace_page_filled.instanceDetails);
        dto.instanceFields = _.cloneDeep(bundleEditor.selected_instace_page_filled.instanceFields.items);

        dispatch(lockScreenRequest());
        return await runner.post(url, configuration.applicationName, authentication.token,
            (status, error) => fromBeError(status, error, () => {
                toast({
                    title: getLabel('common.toaster.title.error'),
                    content: getLabel('common.api.errors.instance.saveTemplate'),
                    type: 'Error'
                });
            }), dto).then((dto: Types.Instance_Details_TYPE) => {
                dispatch(unLockScreenRequest());
                // XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
                //                      Reload all...
                // XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
                toast({
                    title: getLabel('common.toaster.title.info'),
                    content: getLabel('common.api.success.instance.saveTemplate'),
                    type: 'Success'
                });
            }).catch((errorMessage: any) => {
                dispatch(unLockScreenRequest());
                console.error(`Error when save Template from Instance, message ${errorMessage}`);
            });
    };

    const runProcessForced = async (instanceId: number, templateId: number, processingOptions: Types.ProcessingOptions) => {
        const url = `${configuration.api?.recognition}Instance/${instanceId}/processForced?templateFileId=${templateId}`;

        dispatch(lockScreenRequest());
        return await runner.post(url, configuration.applicationName, authentication.token,
            (status, error) => fromBeError(status, error, () => {
                toast({
                    title: getLabel('common.toaster.title.error'),
                    content: getLabel('common.api.errors.instanceBundle.runProcessForced'),
                    type: 'Error'
                });
            }), processingOptions).then(() => {
                dispatch(unLockScreenRequest());
                toast({
                    title: getLabel('common.toaster.title.info'),
                    content: getLabel('common.api.success.instanceBundle.runProcessForced'),
                    type: 'Success'
                });
            }).catch((errorMessage: any) => {
                dispatch(unLockScreenRequest());
                console.error(`Error when runProcessBundle, message ${errorMessage}`);
            });
    };

    const upLoadInstance = async (instanceBundleId: number | undefined, file: File, metadata: string | undefined, textFromDocument: boolean | undefined, onlyBarcode: boolean | undefined): Promise<Types_Rec.Rec_InstanceStatus | undefined> => {
        const url = `${configuration.api?.recognition}Instance/upload`;

        const additionalParams = [];
        if (instanceBundleId)
            additionalParams.push({ name: 'instanceBundleId', value: instanceBundleId });

        if (metadata)
            additionalParams.push({ name: 'metadata', value: metadata });

        if (!_.isNil(textFromDocument))
            additionalParams.push({ name: 'textFromDocument', value: textFromDocument.toString() });

        if (!_.isNil(onlyBarcode))
            additionalParams.push({ name: 'onlyBarcode', value: onlyBarcode.toString() });

        dispatch(lockScreenRequest());
        return await runner.post(url, configuration.applicationName, authentication.token,
            (status, error) => fromBeError(status, error, () => {
                toast({
                    title: getLabel('common.api.errors.instance.uploadInstance'),
                    type: 'Error'
                });
            }), file, true, undefined, additionalParams).then((instanceStatus: Types_Rec.Rec_InstanceStatus) => {
                dispatch(unLockScreenRequest());
                toast({
                    title: getLabel('common.api.success.instance.uploadInstance'),
                    type: 'Success'
                });
                return instanceStatus;
            }).catch((errorMessage: any) => {
                dispatch(unLockScreenRequest());
                console.error(`Error when runProcessBundle, message ${errorMessage}`);
                return undefined;
            });
    };
    // ************************************************************************************************************
    //                                  Hub functions
    // ************************************************************************************************************
    const refreshInstaceStatus = (message: Types_Rec.Rec_Hub_instanceChangeStatus_TYPE) => {
        console.log(`BundleEditorActions - refreshInstaceStatus - message, InstaceID: ${message.instanceId}, Status ${message.processingStatus}`);

        // look in InstanceBundleInstances and update state in case...
        for (let index = 0; index < bundleEditor.instanceBundleInstances.items.length; index++) {
            const instance = bundleEditor.instanceBundleInstances.items[index];

            if (instance.id === message.instanceId) {
                instance.processingStatus = message.processingStatus;

                dispatch(updateAppState('bundleEditor', { ...bundleEditor }));
                break;
            }
        }
    };

    // ************************************************************************************************************
    //                                  Private functions
    // ************************************************************************************************************
    const addInstanceField = async (instanceId: number, pageId: number): Promise<Types.Instance_Field_TYPE | undefined> => {
        console.log(`BundleEditorActions - addInstanceField - message, instanceId: ${instanceId}, pageId ${pageId}`);

        const url = `${configuration.api?.recognition}Instance/${instanceId}/AddInstanceField?pageId=${pageId - 1}`;

        dispatch(lockScreenRequest());
        return await runner.post(url, configuration.applicationName, authentication.token).then((instanceField: Types.Instance_Field_TYPE) => {
            dispatch(unLockScreenRequest());
            return instanceField;
        }).catch((errorMessage: any) => {
            dispatch(unLockScreenRequest());
            console.error(errorMessage);
            return undefined;
        });
    };

    const getInstanceDatails = async (instanceId: number): Promise<Types.Instance_Details_TYPE> => {
        const url = `${configuration.api?.recognition}Instance/${instanceId}`;

        dispatch(lockScreenRequest());
        return await runner.get(url, configuration.applicationName, authentication.token).then((instance: any) => {
            dispatch(unLockScreenRequest());
            return instance;
        }).catch((errorMessage: any) => {
            dispatch(unLockScreenRequest());
            console.error(errorMessage);
            return undefined;
        });
    };

    const getInstancePageFieldsWithAreas = async (instanceId: number, pageSize?: number, pageIndex?: number, documentPageIndex?: number): Promise<Types.Instance_Field_Search_TYPE> => {
        let url = `${configuration.api?.recognition}Instance/${instanceId}/FieldsWithAreas?`;

        if (!_.isNil(pageSize))
            url += `pageSize=${pageSize}&`;

        if (!_.isNil(pageIndex))
            url += `pageIndex=${pageIndex}&`;

        if (!_.isNil(documentPageIndex))
            url += `documentPageId=${documentPageIndex}&`;

        dispatch(lockScreenRequest());
        return await runner.get(url, configuration.applicationName, authentication.token).then((instance: any) => {
            dispatch(unLockScreenRequest());
            return instance;
        }).catch((errorMessage: any) => {
            dispatch(unLockScreenRequest());
            console.error(errorMessage);
            return undefined;
        });
    };

    const getInstanceProcessedPageDetails = async (instanceId: number, page: number): Promise<Types.Instance_Page_Details_TYPE> => {
        const url = `${configuration.api?.recognition}Instance/${instanceId}/ProcessedPageDetails/${page}`;

        dispatch(lockScreenRequest());
        return await runner.get(url, configuration.applicationName, authentication.token).then((templatePayloadPage: any) => {
            dispatch(unLockScreenRequest());
            return templatePayloadPage;
        }).catch((errorMessage: any) => {
            dispatch(unLockScreenRequest());
            console.error(`Error when getInstancePayloadPage, message ${errorMessage}`);
            return undefined;
        });
    };

    const getInstanceBundleTemplates = async (bundleId: number): Promise<Types.Bundle_Template_TYPE[] | undefined> => {
        const url = `${configuration.api?.recognition}InstanceBundle/${bundleId}/Templates`;

        dispatch(lockScreenRequest());
        return await runner.get(url, configuration.applicationName, authentication.token).then((templates: any) => {
            dispatch(unLockScreenRequest());
            return templates;
        }).catch((errorMessage: any) => {
            dispatch(unLockScreenRequest());
            console.error(`Error when getInstanceTemplates, message ${errorMessage}`);
            return undefined;
        });
    };

    const onInstance_Field_Changed = async (field: Types.Instance_Field_TYPE, selectNode: boolean, persist: boolean, forceRefresh: boolean) => {
        console.log(`BundleEditorActions - onInstance_Field_Changed - selectNode ${selectNode}, persist: ${persist},  field: `, field);

        let newField: Types.Instance_Field_TYPE | null = _.cloneDeep(field);

        // Save on BE
        if (persist)
            newField = await saveInstanceField(field);

        if (newField !== null) {
            // Delete the old one
            bundleEditor.selected_instace_page_filled.instanceFields.items =
                bundleEditor.selected_instace_page_filled.instanceFields.items.filter(x => { return x.id !== field.id; });

            // Add the new one
            bundleEditor.selected_instace_page_filled.instanceFields.items.push(newField);

            if (forceRefresh && bundleEditor.documentEditorObject.activePage) {
                const appSelectedInstanceField_Id = bundleEditor.selectedInstanceField_Id;
                console.log(`We need to force a reload to avoidSyncfusion Crash on field change ${appSelectedInstanceField_Id}, newField.id: ${newField.id}`);
                selectInstanceBundleInstance(bundleEditor.documentEditorObject.activePage, undefined);
                setTimeout(() => {
                    if (bundleEditor.documentEditorObject.activePage)
                        selectInstanceBundleInstance(bundleEditor.documentEditorObject.activePage,
                            bundleEditor.selectedInstanceBundleInstance,
                            appSelectedInstanceField_Id);
                }, 300);
            }
            else {
                // Regenerate model
                const documentEditorObject = toSynfusionDocumentEditorObject(bundleEditor.selected_instace_page_filled, forceRefresh);

                // Update state
                dispatch(updateAppState('bundleEditor', {
                    ...bundleEditor,
                    // Reset
                    ...{
                        documentEditorObject,
                        selectedInstanceField_Id: selectNode ? field.id : undefined
                    }
                }));
            }

        }
    };

    const saveInstanceField = async (field: Types.Instance_Field_TYPE): Promise<Types.Instance_Field_TYPE | null> => {
        console.log('BundleEditorActions - saveInstanceField - field: ', field);

        const url = `${configuration.api?.recognition}Instance/${bundleEditor.selectedInstanceBundleInstance.id}/saveField`;

        dispatch(lockScreenRequest());
        return await runner.post(url, configuration.applicationName, authentication.token,
            (status, error) => fromBeError(status, error, () => {
                toast({
                    title: getLabel('common.toaster.title.error'),
                    content: getLabel('common.api.errors.instance.saveField'),
                    type: 'Error'
                });
            }), field).then((fieldDTO: Types.Instance_Field_TYPE) => {
                dispatch(unLockScreenRequest());
                return fieldDTO;
            }).catch((errorMessage: any) => {
                dispatch(unLockScreenRequest());
                console.error(`Error when save Instance Field, message ${errorMessage}`);
                return null;
            });
    };

    const toSynfusionDocumentEditorObject = (instace_page_filled: Types.Instance_Page_Filled_TYPE, forceRefresh: boolean): DocumentEditor_Object => {
        console.log('BundleEditorActions - toSynfusionDocumentEditorObject - instace_page_filled', instace_page_filled);

        const instancePageDetails = instace_page_filled.instancePageDetails ? instace_page_filled.instancePageDetails : {
            pageId: -1,
            imageHeight: 10,
            imageType: 1,
            imageWidth: 10
        };

        const documentEditorObject: DocumentEditor_Object = {
            connectors: [],
            id: instace_page_filled.instanceDetails.id,
            name: instace_page_filled.instanceDetails.name,
            nodes: [],
            pageSettings: undefined,
            activePage: instancePageDetails.pageId + 1,
            totalPages: instace_page_filled.instanceDetails ? instace_page_filled.instanceDetails.numPages : 1
        };

        if (forceRefresh) {
            documentEditorObject.forceRefresh = new Date().getTime();
        }

        // Create nodes
        // 1. Create Background Image
        const height = instancePageDetails.processedImageHeight ?? instancePageDetails.imageHeight;
        const width = instancePageDetails.processedImageWidth ?? instancePageDetails.imageWidth;

        let url = encodeURI(`${configuration.api?.recognition}Instance/${instace_page_filled.instanceDetails.id}/PayloadProcessedPageImgQueryString/${instancePageDetails.pageId}` +
            `?ApplicationName=${configuration.applicationName}&refreshX=${new Date().getTime()}`);

        if (!configuration.authentication?.cookies)
            url += `&sessionToken=${authentication.token}`;

        documentEditorObject.pageSettings = {
            height,
            width,
            orientation: height >= width ? 'Portrait' : 'Landscape',
            boundaryConstraints: 'Diagram',
            background: {
                source: url,
                scale: 'None'
            }
        } as PageSettingsModel;

        // Node Constraints
        const areaRectangleConstraints: NodeConstraints = NodeConstraints.Default & ~NodeConstraints.Delete & ~NodeConstraints.Shadow;
        const valueRectangleConstraints: NodeConstraints =
            ~NodeConstraints.AllowDrop & ~NodeConstraints.Delete & ~NodeConstraints.Drag &
            ~NodeConstraints.InConnect & ~NodeConstraints.OutConnect & ~NodeConstraints.Resize &
            ~NodeConstraints.Rotate & ~NodeConstraints.Shadow;

        // 2. Add all nodes for the current page
        if (instace_page_filled.instanceFields && instace_page_filled.instanceFields.items) {
            for (let index = 0; index < instace_page_filled.instanceFields.items.length; index++) {
                const instance_field = instace_page_filled.instanceFields.items[index];
                const anchorArea = instance_field.areaViewInfo ? instance_field.areaViewInfo.anchorArea : undefined;

                if (instance_field.areaViewInfo && instance_field.areaViewInfo.fieldArea.pageIdx === instace_page_filled.instancePageDetails.pageId) {
                    // Skip bad values
                    if (!instance_field.areaViewInfo || !instance_field.areaViewInfo.fieldArea.rectangle || instance_field.areaViewInfo.fieldArea.rectangle.height === 0 ||
                        instance_field.areaViewInfo.fieldArea.rectangle.width === 0)
                        continue;

                    // **************************************************************************************************
                    // Prime Rectangle
                    // **************************************************************************************************
                    // If Constant do not show a box but just a pink Icon
                    const isConstant = instance_field.fieldConfiguration.fieldContentType === Enums.FieldContentTypes_ENUM.Constant;

                    // If Constant do not show a box but just a lite green Icon
                    const isScript = instance_field.fieldConfiguration.fieldContentType === Enums.FieldContentTypes_ENUM.Script;


                    let borderColor = Editor_Constants.Rectangle_Color_NotValid;
                    if (instance_field.isValid)
                        borderColor = Editor_Constants.Rectangle_Color_Valid;

                    if (isConstant) {
                        borderColor = '#f26d9e';
                    } else if (isScript) {
                        borderColor = '#6df2d7';
                    }

                    // We can have two rectangles, one for the area and another for the value (resultRectangle)
                    const rectangle_Node = {
                        borderWidth: 0,
                        constraints: areaRectangleConstraints,
                        // constraints: NodeConstraints.Resize & NodeConstraints.Select & NodeConstraint & ~NodeConstraints.Delete,
                        // Icon
                        // https://ej2.syncfusion.com/react/documentation/api/diagram/iconShapeModel/
                        // expandIcon: { height: 20, width: 20, shape: 'ArrowUp', fill: borderColor },
                        id: `${Editor_Constants.Rectangle_Node_Partial_Name}${instance_field.id}`,
                        height: instance_field.areaViewInfo.fieldArea.rectangle.height,
                        offsetX: instance_field.areaViewInfo.fieldArea.rectangle.x + instance_field.areaViewInfo.fieldArea.rectangle.width / 2,
                        offsetY: instance_field.areaViewInfo.fieldArea.rectangle.y + instance_field.areaViewInfo.fieldArea.rectangle.height / 2,
                        width: instance_field.areaViewInfo.fieldArea.rectangle.width,
                        style: {
                            fill: Editor_Constants.Rectangle_Color_Fill,
                            opacity: 1,
                            strokeColor: borderColor,
                            strokeWidth: 3
                        }
                    } as NodeModel;
                    documentEditorObject.nodes.push(rectangle_Node);

                    if (instance_field.areaViewInfo.fieldArea.resultRectangle)
                        documentEditorObject.nodes.push({
                            constraints: valueRectangleConstraints,
                            id: `${Editor_Constants.ResultRectangle_Node_Partial_Name}${instance_field.id}`,
                            height: instance_field.areaViewInfo.fieldArea.resultRectangle.height,
                            offsetX: instance_field.areaViewInfo.fieldArea.resultRectangle.x + instance_field.areaViewInfo.fieldArea.resultRectangle.width / 2,
                            offsetY: instance_field.areaViewInfo.fieldArea.resultRectangle.y + instance_field.areaViewInfo.fieldArea.resultRectangle.height / 2,
                            width: instance_field.areaViewInfo.fieldArea.resultRectangle.width,
                            style: {
                                fill: Editor_Constants.ResultRectangle_Color_Fill,
                                opacity: 1,
                                strokeColor: Editor_Constants.ResultRectangle_Color_Border,
                                strokeWidth: 2
                            }
                        } as NodeModel);

                    // **************************************************************************************************
                    // Anchor, not for constant and script
                    // **************************************************************************************************
                    if (anchorArea && !isConstant && !isScript) {
                        // Se empty creo un rettangolo di dimensione anchor result + 5px per lato
                        if (anchorArea.rectangle && anchorArea.rectangle.width > 0) {
                            let borderColor = Editor_Constants.Anchor_Rectangle_Color_NotValid;
                            if (anchorArea.fieldValid)
                                borderColor = Editor_Constants.Anchor_Rectangle_Color_Valid;

                            const anchorRectangle_Node = {
                                borderWidth: 0,
                                constraints: areaRectangleConstraints,
                                id: `${Editor_Constants.Anchor_Rectangle_Node_Partial_Name}${instance_field.id}`,
                                height: anchorArea.rectangle.height,
                                offsetX: anchorArea.rectangle.x + anchorArea.rectangle.width / 2,
                                offsetY: anchorArea.rectangle.y + anchorArea.rectangle.height / 2,
                                width: anchorArea.rectangle.width,
                                style: {
                                    fill: Editor_Constants.Anchor_Rectangle_Color_Fill,
                                    opacity: 1,
                                    strokeColor: borderColor,
                                    strokeWidth: 3
                                }
                            } as NodeModel;
                            documentEditorObject.nodes.push(anchorRectangle_Node);

                            // **************************************************************************************************
                            // Add Connector
                            // **************************************************************************************************
                            if (!documentEditorObject.connectors)
                                documentEditorObject.connectors = [];

                            documentEditorObject.connectors.push({
                                sourceID: rectangle_Node.id,
                                style: {
                                    opacity: 1,
                                    strokeColor: borderColor,
                                    strokeWidth: 3
                                },
                                sourceDecorator: {
                                    shape: 'Circle',
                                    style: {
                                        opacity: 1,
                                        strokeColor: borderColor,
                                        strokeWidth: 3
                                    }
                                },
                                targetDecorator: {
                                    shape: 'Circle',
                                    style: {
                                        opacity: 1,
                                        strokeColor: borderColor,
                                        strokeWidth: 3
                                    }
                                },
                                targetID: anchorRectangle_Node.id,
                                type: 'Orthogonal'
                            } as ConnectorModel);
                        }
                        // **************************************************************************************************
                        // Anchor Found Value
                        // **************************************************************************************************
                        if (anchorArea.resultRectangle && anchorArea.resultRectangle.width > 0) {
                            documentEditorObject.nodes.push({
                                constraints: valueRectangleConstraints,
                                id: `${Editor_Constants.Anchor_ResultRectangle_Node_Partial_Name}${instance_field.id}`,
                                height: anchorArea.resultRectangle.height,
                                offsetX: anchorArea.resultRectangle.x + anchorArea.resultRectangle.width / 2,
                                offsetY: anchorArea.resultRectangle.y + anchorArea.resultRectangle.height / 2,
                                width: anchorArea.resultRectangle.width,
                                style: {
                                    fill: Editor_Constants.Anchor_ResultRectangle_Color_Fill,
                                    opacity: 0.3,
                                    strokeColor: Editor_Constants.Anchor_ResultRectangle_Color_Border,
                                    strokeWidth: 0
                                }
                            } as NodeModel);
                        }
                    }
                }
            }
        }
        return documentEditorObject;
    };

    return {
        deleteInstanceBundle,
        deleteInstanceBundleInstance,
        instanceBundle_export,
        // XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
        onDownLoadJsonInstance,
        loadDumpDocument,
        loadDumpDocumentAdvanced,
        loadDumpField,
        // XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
        loadInstanceBundles,
        loadInstanceBundleInstances,
        onAddField,
        onInstance_Field_Persist,
        onInstance_Field_Process,
        onInstance_Field_Amend_Changed,
        onInstance_Field_Amend_Save,
        onInstance_Field_Configuration_PropertyChange,
        onNodeMoved,
        onNodeSizeChange,
        refreshInstaceStatus,
        runProcessForced,
        runProcessInstanceBundle,
        runProcessInstance,
        runProcessInstancesAll,
        runProcessInstanceRelated,
        saveTemplateFromInstance,
        selectInstanceField,
        selectInstanceBundle,
        selectInstanceBundleInstance,
        upLoadInstance
    };
}