import _ from 'lodash';
import { Components, Utils } from '@firedesktop/react-base';
import { useDispatch, useSelector } from 'react-redux';

import * as Types from '../Config/Types';
import * as Types_Rpa from '../Config/Types_Rpa';

import { initialState } from '../Redux/initialState';


export default function _BaseActions() {
    const authentication = useSelector((state: Types.InitialState_TYPE) => state.authentication);
    const configuration = useSelector((state: Types.InitialState_TYPE) => state.configuration);
    const configurationBE_Recognition = useSelector((state: Types.InitialState_TYPE) => state.configurationBE_Recognition);
    const configurationBE_Runtime = useSelector((state: Types.InitialState_TYPE) => state.configurationBE_Runtime);
    const freeSpace = useSelector((state: Types.InitialState_TYPE) => state.freeSpace);
    const labels = useSelector((state: Types.InitialState_TYPE) => state.labels);

    const dispatch = useDispatch();
    const lLoader = Utils.Labels.LanguageManager();

    const runner = new Utils.Fetch.FetchWrapper();

    const getConfigurationBE_Recognition = (): Types.Configuration_BE_Recognition_State_TYPE => {
        return _.cloneDeep(configurationBE_Recognition);
    };


    const fromBeError = async (status: number, error: any, alterntiveAction: () => void) => {
        const message = await error.text();
        if (status === 400 && message) {
            toast({
                title: getLabel('common.toaster.title.error'),
                content: message,
                type: 'Error'
            });
        }
        else
            alterntiveAction();
    };
    // ******************************************************************************************************
    //                  State
    // ******************************************************************************************************
    const resetAppState = () => {
        const _initialState = initialState();
        return {
            type: Types.ACTIONS.UPDATE_BULK_APP_STATE,
            payload: {
                values: { ..._initialState, configuration, configurationBE_Recognition, configurationBE_Runtime, labels }
            }
        };
    };

    const configurationAppState = (configuration: Types.ConfigurationState_TYPE,
        configurationBE_Recognition: Types.Configuration_BE_Recognition_State_TYPE,
        configurationBE_Runtime: Types_Rpa.Configuration_BE_Runtime_State_TYPE,
        labels: any) => {
        return {
            type: Types.ACTIONS.UPDATE_BULK_APP_STATE,
            payload: {
                values: { configuration, configurationBE_Recognition, configurationBE_Runtime, labels }
            }
        };
    };

    const updateAppState = (name: Types.State_NAMES, value: any) => {
        return {
            type: Types.ACTIONS.UPDATE_APP_STATE,
            payload: {
                name,
                value
            }
        };
    };

    const updateAppStateGeneric = (name: string, value: any) => {
        return {
            type: Types.ACTIONS.UPDATE_APP_STATE,
            payload: {
                name,
                value
            }
        };
    };

    const updateAppStateFreeSpace = (name: string, value: any) => {
        return {
            type: Types.ACTIONS.UPDATE_APP_STATE,
            payload: {
                name: 'freeSpace',
                value: { ...freeSpace, [name]: value }
            },
        };
    };

    const lockScreenRequest = () => {
        return {
            type: Types.ACTIONS.SCREEN_LOCK_REQUEST,
        };
    };

    const unLockScreenRequest = () => {
        return {
            type: Types.ACTIONS.SCREEN_UNLOCK_REQUEST,
        };
    };

    const toast = (toastObject: Components.Toaster_Types.Toaster_Prop_Type) => {
        dispatch(updateAppState('toaster', toastObject));
    };

    // ******************************************************************************************************
    //                  Labels
    // ******************************************************************************************************
    const getLabel_EntireObject = (id: string): any => {
        return lLoader.getLabel(labels, id);
    };

    const getLabel = (id: string): string => {
        return lLoader.getLabel(labels, id);
    };

    const getLabelValueArraySorted = (id: string): Array<Types.Common_Label_Value_Type> => {
        const appArr = lLoader.getLabel(labels, id);
        return Array.isArray(appArr) ? appArr.sort(function (a, b) {
            if (_.toNumber(a.label) < _.toNumber(b.label)) return -1;
            if (_.toNumber(a.label) > _.toNumber(b.label)) return 1;
            return 0;
        }) : [];
    };

    const getLabelFromMyLabels = (labels: object, id: string): string => {
        return lLoader.getLabel(labels, id);
    };

    const getLabelFromMyLabelsByStateName = (labelStateName: string, id: string): any => {
        return lLoader.getLabel(freeSpace[labelStateName], id);
    };

    // ******************************************************************************************************
    //                  BE Configuration
    // ******************************************************************************************************
    const loadConfigurationBE_Recognition = async (configuration: Types.ConfigurationState_TYPE): Promise<Types.Configuration_BE_Recognition_State_TYPE | undefined> => {
        if (authentication.token) {
            const url = `${configuration.api?.recognition}AppConfiguration/Base`;
            console.log(`Recognition - BE Configuration Loading, URL: ${url}`);
            dispatch(lockScreenRequest());
            return await runner.get(url, configuration.applicationName, authentication.token).then((configurationBE: Types.Configuration_BE_Recognition_State_TYPE) => {
                dispatch(unLockScreenRequest());
                console.log('Recognition - Be Configuration Loaded', configurationBE);
                return configurationBE;
            }).catch((errorMessage: any) => {
                dispatch(unLockScreenRequest());
                console.warn(`Recognition - Be Configuration NOT Loaded, ${errorMessage}`);
                return undefined;
            });
        }
    };

    const loadConfigurationBE_Runtime = async (configuration: Types.ConfigurationState_TYPE): Promise<Types_Rpa.Configuration_BE_Runtime_State_TYPE | undefined> => {
        if (authentication.token) {
            const url = `${configuration.api?.runtime}AppConfiguration/Base`;
            console.log(`Runtime - BE Configuration Loading, URL: ${url}`);
            dispatch(lockScreenRequest());
            return await runner.get(url, configuration.applicationName, authentication.token).then((configurationBE: Types_Rpa.Configuration_BE_Runtime_State_TYPE) => {
                dispatch(unLockScreenRequest());
                console.log('Runtime - Be Configuration Loaded', configurationBE);
                return configurationBE;
            }).catch((errorMessage: any) => {
                dispatch(unLockScreenRequest());
                console.warn(`Runtime - Be Configuration NOT Loaded, ${errorMessage}`);
                return undefined;
            });
        }
    };

    const loadConfigurationBE_Authentication = async (configuration: Types.ConfigurationState_TYPE): Promise<Types.ConfigurationState_TYPE | undefined> => {
        if (authentication.token) {
            const url = `${configuration.api?.authentication}AppConfiguration/Configuration/${process.env.REACT_APP_TARGET}`;
            console.log(`Authentication - BE Configuration Loading, URL: ${url}`);
            dispatch(lockScreenRequest());
            return await runner.get(url, configuration.applicationName, authentication.token).then((configurationBE: Types.ConfigurationState_TYPE) => {
                dispatch(unLockScreenRequest());
                console.log('Authentication - Be Configuration Loaded', configurationBE);
                return configurationBE;
            }).catch((errorMessage: any) => {
                dispatch(unLockScreenRequest());
                console.warn(`Authentication - Be Configuration NOT Loaded, ${errorMessage}`);
                return {
                    // True because we do not want to lock fe
                    loadedBE: true
                } as any;
            });
        }
    };

    const loadLanguageBE_Authentication = async (configuration: Types.ConfigurationState_TYPE, language: string): Promise<Types.ConfigurationState_TYPE | undefined> => {
        if (authentication.token) {
            const url = `${configuration.api?.authentication}AppConfiguration/Language/${language}/${process.env.REACT_APP_TARGET}`;
            console.log(`Authentication - BE Language Loading, URL: ${url}`);
            dispatch(lockScreenRequest());
            return await runner.get(url, configuration.applicationName, authentication.token).then((configurationBE: Types.ConfigurationState_TYPE) => {
                dispatch(unLockScreenRequest());
                console.log('Authentication - Be Language Loaded', configurationBE);
                return configurationBE;
            }).catch((errorMessage: any) => {
                dispatch(unLockScreenRequest());
                console.warn(`Authentication - Be Language NOT Loaded, ${errorMessage}`);
                return {
                    // True because we do not want to lock fe
                    loadedBE: true
                } as any;
            });
        }
    };

    const loadConfigurationBE = async (configuration: Types.ConfigurationState_TYPE | undefined, labels: any) => {
        if (configuration) {
            // Load configuration from BE and merge with the one in FE
            const newConfiguration = await loadConfigurationBE_Authentication(configuration);
            const mergedConfiguration = _.merge(configuration, newConfiguration);

            const newLanguage = await loadLanguageBE_Authentication(configuration, labels.language);
            const mergedLanguage = _.merge(labels, newLanguage);

            let configurationBE_Recognition_index = -1;
            let configurationBE_Recognition;
            let configurationBE_Runtime_index = -1;
            let configurationBE_Runtime;

            const arr: any[] = [];

            if (mergedConfiguration?.recognition) {
                if (mergedConfiguration?.api?.recognition) {
                    configurationBE_Recognition_index = arr.length;
                    arr.push(loadConfigurationBE_Recognition(mergedConfiguration));
                }
                else
                    console.warn('You are trying to load recognition configurationBE but there\'s no recognition api configured');
            }
            if (mergedConfiguration?.runtime) {
                if (mergedConfiguration?.api?.runtime) {
                    configurationBE_Runtime_index = arr.length;
                    arr.push(loadConfigurationBE_Runtime(mergedConfiguration));
                }
                else
                    console.warn('You are trying to load runtime configurationBE but there\'s no runtime api configured');
            }

            const promiseResults = await Promise.all(arr);

            if (configurationBE_Recognition_index > -1)
                configurationBE_Recognition = promiseResults[configurationBE_Recognition_index];

            if (configurationBE_Runtime_index > -1)
                configurationBE_Runtime = promiseResults[configurationBE_Runtime_index];

            configurationAppState(mergedConfiguration, configurationBE_Recognition, configurationBE_Runtime, mergedLanguage);
        }
    };

    return {
        fromBeError,
        getConfigurationBE_Recognition,
        getLabel,
        getLabel_EntireObject,
        getLabelFromMyLabels,
        getLabelFromMyLabelsByStateName,
        getLabelValueArraySorted,
        loadConfigurationBE,
        lockScreenRequest,
        resetAppState,
        toast,
        updateAppState,
        updateAppStateFreeSpace,
        updateAppStateGeneric,
        unLockScreenRequest
    };
}