import _BaseHub from './_BaseHub';

import * as Types from '../Config/Types';
import * as Types_Rpa from '../Config/Types_Rpa';

interface IActions {
    updateCommands: (envelope: Types_Rpa.Rpa_Hub_ScriptCommandsUpdate_Message_TYPE) => void
    logMessage: (envelope: Types_Rpa.Rpa_Hub_LogMessage_TYPE) => void
}

export default class RuntimeHub extends _BaseHub {
    actions: IActions | undefined;

    public getMyName(): string {
        return 'Runtime';
    }

    public getMyHubUrl(configuration: Types.ConfigurationState_TYPE): string | undefined {
        return configuration.hubs?.runtime;
    }

    public setActions(actions: IActions) {
        this.actions = actions;
    }

    protected setHubConnectionActions(): void {
        if (this.connection) {
            console.log(`${this.getMyName()} - Hub Connected!, ConnectionId: ${this.connection.connectionId}`);

            this.connection.on('RpaScriptCommandsUpdate', envelop => {
                this.rpaScriptCommandsUpdate(envelop);
            });
            this.connection.on('RpaLog', envelop => {
                this.rpaScriptRpaLog(envelop);
            });
        }
    }

    // *********************************************************************************************************
    //      Actions
    // *********************************************************************************************************
    rpaScriptCommandsUpdate = (envelope: Types_Rpa.Rpa_Hub_ScriptCommandsUpdate_Message_TYPE) => {
        if (this.actions)
            this.actions.updateCommands(envelope);
    };

    rpaScriptRpaLog = (envelope: Types_Rpa.Rpa_Hub_LogMessage_TYPE) => {
        if (this.actions)
            this.actions.logMessage(envelope);
    };
}
