import { useState } from 'react';
import AuthenticationActions from '../../../actions/Authentication/AuthenticationActions';

export default function useResetPasswordRequestPage() {
    const [form, setForm] = useState({ usernameOrMail: '' });

    const { changePasswordRequest } = AuthenticationActions();

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value });
    };

    const onClickSend = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        changePasswordRequest(form.usernameOrMail);
    };

    return {
        form,
        onChange,
        onClickSend
    };
}