export const Authentication = () => {
    return {
        AccessRights: {
            Accounting: {
                PassiveInvoice: {
                    Read: 'Accounting_PassiveInvoice',
                    Update: 'Accounting_PassiveInvoice_Post'
                }
            },
            Authentication: {
                Authority: {
                    Update: 'Auth_Authority_Post',
                    Delete: 'Auth_Authority_Delete'
                },
                Contact: {
                    Update: 'Auth_Contact_Post',
                    Delete: 'Auth_Contact_Delete'
                },
                Group: {
                    Update: 'Auth_Group_Post',
                    Delete: 'Auth_Group_Delete'
                },
                Tenant: {
                    Update: 'Auth_Tenant_Post',
                    Delete: 'Auth_Tenant_Delete'
                },
                User: {
                    Update: 'Auth_User_Post',
                    Delete: 'Auth_User_Delete'
                }
            },
            Contract: {
                ContractualForm: {
                    Update: 'Contract_ContractualForm_Post',
                    Delete: 'Contract_ContractualForm_Delete'
                },
                ContractNature: {
                    Update: 'Contract_ContractNature_Post',
                    Delete: 'Contract_ContractNature_Delete'
                },
                ContractTemplate: {
                    Update: 'Contract_ContractTemplate_Post',
                    Delete: 'Contract_ContractTemplate_Delete'
                },
                ContractType: {
                    Update: 'Contract_ContractType_Post',
                    Delete: 'Contract_ContractType_Delete'
                },
                Document: {
                    MoveDocumentToContract: 'Contract_MoveDocumentToContract'
                },
                Product: {
                    Update: 'Contract_Product_Post',
                    Delete: 'Contract_Product_Delete'
                },
                Delete: 'Contract_Contract_Delete',
                Edit: 'Contract_Contract_Edit',
                Export: 'Contract_Contract_Export',
                Update: 'Contract_Contract_Post'
            },
            Doc: {
                DocumentCheckItem: {
                    AddMissingDocumentCheckItemToInstances: 'Doc_DocumentCheckListModel_AddMissingDocumentCheckItem'
                },
                DocumentCheckList: {
                    Document_Delete: 'Doc_DocumentCheckList_Delete',
                },
                Document: {
                    FieldsLock: 'Doc_Document_FieldsLock',
                    FileMerge: 'Doc_Document_Merge',
                    Signature: {
                        Sign: 'Doc_Document_Sign',
                        Validate: 'Doc_Document_SignValidate'
                    }
                }
            }
        }
    };
};

export const Recognition_DocumentEditor = () => {
    return {
        Rectangle_Node_Partial_Name: 'primary_node_rec_',
        Rectangle_Color_Valid: '#54e833',
        Rectangle_Color_NotValid: '#eb1c31',
        Rectangle_Color_Fill: 'none',

        ResultRectangle_Node_Partial_Name: 'primary_node_res_rec_',
        ResultRectangle_Color_Border: '#eb9a0e',
        ResultRectangle_Color_Fill: 'none',

        Anchor_Rectangle_Node_Partial_Name: 'anchor_node_rec_',
        Anchor_Rectangle_Color_Valid: '#1765FE',
        Anchor_Rectangle_Color_NotValid: '#1765FE',
        Anchor_Rectangle_Color_Fill: 'none',

        Anchor_ResultRectangle_Node_Partial_Name: 'anchor_node_res_rec_',
        Anchor_ResultRectangle_Color_Border: '#eb9a0e',
        Anchor_ResultRectangle_Color_Fill: '#6BA5D7'
    };
};

export const Rpa_DocumentEditor = () => {
    return {
        Child_Value: {
            Bottom: 'true',
            Right: 'false'
        },
        DefaultLabelValue: '',
        Dropped_Node_Starting_Name: 'droppedNode_',
        Palaette_Flow_Color: '#6BA5D7',
        Palaette_Image_Color: '#f7f9fa',
        PalaetteConnectorColor: '#6BA5D7',
        PalaettObjectColor_Node_Commented: '#c6eb34',
        PalaettObjectColor_Node_NotEnabled: '#e68143',
        PalaettObjectColor_Node_PausedBeforeExecution: '#d9142e',
        PalaettObjectColor_Node_WithBadConnetion: '#db21b0',
        PalaettObjectColor_Node_Highlighted: '#07f702',
        Ports: {
            Ids: {
                Bottom: 'port_bottom',
                Left: 'port_left',
                Right: 'port_right',
                Top: 'port_top'
            }
        },
        ScriptFileExtensions: '.fbl',
        SelectionNameDefaultValue: 'New Variable',
        Start_Command_Name: 'StartTaskCommand',
        Stop_Command_Name: 'StopTaskCommand'
    };
};

export const SheetDimension = () => {
    return {
        A3: {
            height: 4961,
            width: 3605
        },
        A4: {
            height: 3508,
            width: 2480
        },
        A5: {
            height: 2480,
            width: 1748
        },
        A6: {
            height: 1748,
            width: 1240
        }
    };
};

// ******************************************************************************************
//                  Coming from js/constants.js based on the APP TARGET
// ******************************************************************************************
export let BasicTheme = {
    baseColor: '#0057FF',
    table: {
        selectedRowBackground: '#b9cef0'
    },
};
try {
    // @ts-ignore
    BasicTheme = basicTheme;
} catch (err) { }