import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { registerLicense } from '@syncfusion/ej2-base';
import { createRoot } from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';


// **************************************************************************************************************
//                                          In App Styles
//
//      In here we just ony put styles from node modules and not custom css, that are in Public Folder. AntD is in App.css
//
// **************************************************************************************************************

// Styles
import '@syncfusion/ej2/bootstrap4.css'; // this should be enough to get all the bootstrap 4 css
import '@firedesktop/react-base/dist/styles/base.css';
import '@firedesktop/react-base/dist/styles/syncfusion_bootstrap4.css';

// Fonts
import '@fortawesome/fontawesome-free/css/all.css';

// ****************************************************************************************************
//  Syncfusion Doc
//      https://ej2.syncfusion.com/react/documentation/licensing/license-key-registration/#reactjs
//      https://help.syncfusion.com/common/essential-studio/licensing/registering-license-keys
//
//   License:
//          https://www.syncfusion.com/account/login
//          Product: Essential Studio Enterprise Edition Binary with TestStudio
//          Platform: Javascript
//          
//
//  ReactJs
//      https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html
//
//  When update this license, also update the license and the verion in react-base
//
// ****************************************************************************************************
registerLicense('ORg4AjUWIQA/Gnt2VlhhQlJCfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5ad01jWnxYdXRTQGlb');

/**
 *                                        MSal
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 *      - Where to setup An Application https://portal.azure.com
 *      - React Tutorial: https://docs.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-react
 */
let azureAD_Enabled = false;
let msalConfig: any;
try {
    // @ts-ignore
    if (msalConfiguration) {
        azureAD_Enabled = true;
        // @ts-ignore
        msalConfig = msalConfiguration().msalConfig;
    }
} catch (err) { }

const container = document.getElementById('root');
if (container) {
    const root = createRoot(container);
    root.render(
        <>
            {/* if we use React.StrictMode Navbar NavDropdown does not work anymore */}
            {azureAD_Enabled ?
                // @ts-ignore
                <MsalProvider instance={new PublicClientApplication(msalConfig)}>
                    <App />
                </MsalProvider>
                :
                <App />}
        </>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
