import { Components } from '@firedesktop/react-base';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import _BaseActions from '../../../actions/_BaseActions';
import AzureADActions from '../../../actions/Authentication/AzureADActions';

import * as Constants from '../../../Config/Constants';
import * as Types from '../../../Config/Types';

import useLoginPage from './useLoginPage';

function LoginPage() {
    const configuration = useSelector((state: Types.InitialState_TYPE) => state.configuration);
    const { form, onChange, onClickLogin } = useLoginPage();

    const { getLabel } = _BaseActions();
    const { azureAD_Enabled, azureAD_Login } = AzureADActions(true);

    const companyName = <span>Flowbot &reg; {`${new Date().getFullYear()}`} FIREDESKTOP SRL</span>;
    const subTitle = getLabel('loginPage.subTitle') !== 'loginPage.subTitle' ? getLabel('loginPage.subTitle') : '';

    return (
        <>
            <div className="login_main">
                <div className="login_form_center">
                    <div className="login_box text-center">
                        <div className="login_logo">
                            <Components.AppIcon name={'logo-main'} className={'contract-login-svg'}
                                fill={Constants.BasicTheme.baseColor} stroke={Constants.BasicTheme.baseColor} />
                        </div>
                        <h3 className="fz36 mb-0">{getLabel('loginPage.title')}</h3>
                        {subTitle ? <h3 className="fz20 mb-3">{subTitle}</h3> : ''}
                        <form>
                            <Components.AppInput
                                appIcon={<Components.AppIcon iconHeight={24} iconWidth={24} name={'user'} className={'tertiary-svg'} iconClassName='login_form_svg' />}
                                className='appInput_form_for_icon'
                                name='username'
                                onChange={onChange}
                                placeholder={getLabel('loginPage.username_placeholder')}
                                type='text'
                                value={form.username}
                            />
                            <Components.AppInput
                                appIcon={<Components.AppIcon name={'password'} className={'tertiary-svg'} iconClassName='login_form_pass_svg' />}
                                className='appInput_form_for_icon'
                                name='password'
                                onChange={onChange}
                                placeholder={getLabel('loginPage.password_placeholder')}
                                type='password'
                                value={form.password}
                            />
                            <button className="login_btn mt-3" onClick={(e: React.MouseEvent<HTMLButtonElement>) => onClickLogin(e)}>{getLabel('loginPage.login_button')}</button>
                            {azureAD_Enabled ? <>
                                <div className="text-center" style={{ marginTop: 10 }}>
                                    <span className="login_with">or login with</span>
                                </div>
                                <hr />
                                <div style={{ maxWidth: 150 }}>
                                    <TooltipComponent content={getLabel('loginPage.azureADTooltip')} position="TopCenter">
                                        <img src="./image/microsoftAzureActiveDirectory.png" alt='AzureAD Logo'
                                            onClick={azureAD_Login}
                                            style={{ cursor: 'pointer' }} width='40%' />
                                    </TooltipComponent>
                                </div>
                            </> : ''}
                        </form>
                        {configuration && configuration.authentication?.enableForgetPassord ? <Row style={{ marginTop: 15 }}>
                            <Col>
                                <a href="#/ResetPasswordRequest">{getLabel('loginPage.forgetPasswordQuestion')}</a>
                            </Col>
                        </Row> : ''}
                        <p style={{ fontSize: 11, marginTop: 20, marginBottom: -40 }}>{companyName}</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LoginPage;