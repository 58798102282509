import { Components } from '@firedesktop/react-base';
import { createInitialState_documentEditor_object } from '../Components/Designers/DocumentEditor';
import * as Types from '../Config/Types';
import * as Types_Rpa from '../Config/Types_Rpa';


const createInstaceFilled = (): Types.Instance_Page_Filled_TYPE => {
    return {
        instanceDetails: {},
        instanceFields: {},
        instancePageDetails: {}
    } as Types.Instance_Page_Filled_TYPE;
};

const createInstaceFieldAmend = (instanceField: Types.Instance_Field_TYPE, value?: string): Types.Instance_Field_Amend_TYPE => {
    return {
        instanceFieldId: instanceField.id,
        instanceId: instanceField.instanceId,
        key: instanceField.key,
        label: '',
        type: instanceField.type,
        value
    } as Types.Instance_Field_Amend_TYPE;
};

const creatFieldArea = (pageIdx: number): Types.FieldArea_TYPE => {
    return {
        fieldValid: false,
        foundValue: '',
        hasConstValue: false,
        hasRule: false,
        name: '',
        pageIdx,
        resultRectangle: undefined,
        rectangle: undefined
    } as Types.FieldArea_TYPE;
};

const createAnchorCfg = (): Types.AnchorCfg_TYPE => {
    return {
        fieldType: 0,
        keyString: '',
        outType: 0,
        validatorCfg: createValidatorCfg(),
        wholePage: false
    } as Types.AnchorCfg_TYPE;
};

const createValidatorCfg = (): Types.ValidatorCfg_TYPE => {
    return {
        collateMatchResults: false,
        collateResults: false,
        validatorType: '',
        expression: ''
    } as Types.ValidatorCfg_TYPE;
};

const initialState = (): Types.InitialState_TYPE => {
    return {
        actualTime: '---',
        authentication: {
            authenticationMode: 0,
            language: 'IT',
            sessionId: '',
            token: '',
            userId: '',
            userName: ''
        },
        bank: {
            banks: {
                items: [],
                itemsPerPage: 20,
                page: 1,
                search: '',
                sort: 'Name',
                totalItems: 0
            }
        },
        bundleEditor: {
            documentEditorObject: createInitialState_documentEditor_object(),
            instanceBundles: {
                items: [] as Array<Types.InstanceBundle_TYPE>,
                itemsPerPage: 10,
                page: 1,
                sort: '',
                totalItems: 0
            } as Types.InstanceBundle_Search_Type,
            instanceBundleInstances: {
                items: [] as Array<Types.InstanceBundleInstance_TYPE>,
                itemsPerPage: 10,
                page: 1,
                sort: '',
                totalItems: 0
            } as Types.InstanceBundleInstance_Search_Type,
            instanceBundleTemplates: [],
            selectedInstanceField_Id: undefined,
            selectedInstanceBundle: {} as Types.InstanceBundle_TYPE,
            selectedInstanceBundleInstance: {} as Types.InstanceBundleInstance_TYPE,
            selected_instace_page_filled: createInstaceFilled(),
        } as Types.BundleEditorState_TYPE,
        company: {
            selectedAvailableUserId: '',
            selectedCompanyAvailableId: -1,
            selectedCompanyId: -1,
            selectedTypeId: -1,
            selectedLocationId: -1,
            selectedRelatedId: -1,
            selectedContactId: -1,
            selectedContactDetailId: -1,
            selectedUserId: '',
            language: '',
            contactTypes: [],
            avaliableUser: {
                items: [],
                itemsPerPage: 20,
                page: 1,
                search: '',
                sort: 'Name',
                totalItems: 0
            },
            companies: {
                items: [],
                itemsPerPage: 20,
                page: 1,
                search: '',
                sort: 'Name',
                totalItems: 0
            },
            companyAvailable: {
                items: [],
                itemsPerPage: 10,
                page: 1,
                search: '',
                sort: 'Name',
                totalItems: 0
            },
            companyAvailableUser: {
                items: [],
                itemsPerPage: -1,
                page: 1,
                search: '',
                sort: 'Name',
                totalItems: 0
            },
            companyType: {
                items: [],
                itemsPerPage: 5,
                page: 1,
                search: '',
                sort: 'Name',
                totalItems: 0
            },
            cities: {
                items: [],
                itemsPerPage: -1,
                page: 1,
                search: '',
                sort: 'Name',
                totalItems: 0
            },
            companyAvailableType: {
                items: [],
                itemsPerPage: 20,
                page: 1,
                search: '',
                sort: 'Name',
                totalItems: 0
            },
            companyLocation: {
                items: [],
                itemsPerPage: 3,
                search: '',
                page: 1,
                sort: 'Description',
                totalItems: 0
            },
            companyContact: {
                items: [],
                itemsPerPage: 10,
                search: '',
                page: 1,
                sort: 'Label',
                totalItems: 0
            },
            companyRelated: {
                items: [],
                itemsPerPage: 5,
                page: 1,
                search: '',
                sort: 'Name',
                totalItems: 0
            },
            companyUser: {
                items: [],
                itemsPerPage: 5,
                page: 1,
                search: undefined,
                sort: undefined,
                totalItems: 0
            },
            countries: {
                items: [],
                itemsPerPage: -1,
                page: 1,
                search: '',
                sort: 'Name',
                totalItems: 0
            },
            contactStatus: [],
            currecies: {
                items: [],
                itemsPerPage: -1,
                page: 1,
                search: '',
                sort: 'Name',
                totalItems: 0
            },
            locationTypes: [],
            regions: {
                items: [],
                itemsPerPage: -1,
                page: 1,
                search: '',
                sort: 'Name',
                totalItems: 0
            },
            states: {
                items: [],
                itemsPerPage: -1,
                page: 1,
                search: '',
                sort: 'Name',
                totalItems: 0
            },
            userAuthorities: {
                items: [],
                itemsPerPage: -1,
                page: 1,
                search: '',
                sort: 'Name',
                totalItems: 0
            }
        },
        configuration: {
            applicationName: '',
            loaded: false,
            locale: 'it-IT',
            api: {
                authentication: '',
                company: '',
                contract: '',
                contractBundle: '',
                doc: '',
                flow: '',
                recognition: '',
                registry: '',
                runtime: '',
                ta: '',
                utils: ''
            },
            authentication: {
                localStorageName: '',
                powerUser: false
            },
            hubs: {
                recognition: '',
                runtime: ''
            },
            baseConfiguration: {
                documentClass: false,
                companyType: false,
                contanctRole: false,
                contract: {
                    contanctType: false,
                    contractualForms: false,
                    productType: false
                }
            },
            alerts: {
                configuration: false,
                runtime: false
            },
            codeGenerator: {
                metaModel: true
            },
            companies: false,
            contract: {
                contractDetailsOnPage: 'Normal',
                enabled: false
            },
            document: {
                commmandType: false,
                documentConfiguration: false,
                entity: false
            },
            jhContract: false,
            missingDocument: false,
            recognition: false,
            runtime: false,
            ta: false,
            task: {
                configuration: false,
                runtime: false
            },
            userManagement: false
        },
        configurationBE_Recognition: {
            fieldContentTypes: [],
            instance_MatchingStatusTypes: [],
            validators: {
                valueTypes: []
            }
        },
        configurationBE_Runtime: {},
        document: {
            commandsViewer: {
                activeCommand: undefined,
                loadedItems: {
                    items: [],
                    itemsPerPage: 10,
                    page: 1,
                    sort: 'label asc',
                    totalItems: 0
                },
                searchText: ''
            },
            commands_all: {
                items: [],
                itemsPerPage: 10000,
                page: 1,
                sort: 'Label asc',
                totalItems: 0
            },
            documentClasses_all: {
                items: [],
                itemsPerPage: 10000,
                page: 1,
                sort: 'Name asc',
                totalItems: 0
            },
            documentEntities_all: {
                items: [],
                itemsPerPage: 10000,
                page: 1,
                sort: 'label asc',
                totalItems: 0
            },
            documentBundles: {
                activeDocumentBundle: undefined,
                documentBundleDocuments: {
                    activeDocumentBundleDocument: undefined,
                    loadedItems: {
                        items: [],
                        itemsPerPage: 10,
                        page: 1,
                        sort: 'Pos asc',
                        totalItems: 0
                    },
                    searchText: '',
                    signatureModels: {
                        activeSignatureModel: undefined,
                        loadedItems: {
                            items: [],
                            itemsPerPage: 10,
                            page: 1,
                            sort: 'Id asc',
                            totalItems: 0
                        },
                        searchText: ''
                    },
                    templates: {
                        activeTemplate: undefined,
                        loadedItems: {
                            items: [],
                            itemsPerPage: 10,
                            page: 1,
                            sort: 'DocumentTemplate.Name asc',
                            totalItems: 0
                        },
                        searchText: ''
                    }
                },
                loadedItems: {
                    items: [],
                    itemsPerPage: 10,
                    page: 1,
                    sort: 'Name asc',
                    totalItems: 0
                },
                searchText: ''
            },
            documentTemplates: {
                activeDocumentTemplate: undefined,
                documentTemplateSections: {
                    activeDocumentTemplateSection: undefined,
                    documentTemplateSectionParameters: {
                        activeDocumentTemplateSectionParameter: undefined,
                        documentTemplateSectionParameterValues: {
                            loadedItems: {
                                items: [],
                                itemsPerPage: 10,
                                page: 1,
                                sort: 'Id asc',
                                totalItems: 0
                            },
                            searchText: ''
                        },
                        loadedItems: {
                            items: [],
                            itemsPerPage: 10,
                            page: 1,
                            sort: 'Name asc',
                            totalItems: 0
                        },
                        searchText: ''
                    },
                    loadedItems: {
                        items: [],
                        itemsPerPage: 10,
                        page: 1,
                        sort: 'Name asc',
                        totalItems: 0
                    },
                    searchText: ''
                },
                loadedItems: {
                    items: [],
                    itemsPerPage: 10,
                    page: 1,
                    sort: 'Name asc',
                    totalItems: 0
                },
                searchText: ''
            },
            entitiesViewer: {
                activeEntity: undefined,
                loadedItems: {
                    items: [],
                    itemsPerPage: 10,
                    page: 1,
                    sort: 'label asc',
                    totalItems: 0
                },
                searchText: ''
            }
        },
        freeSpace: {
        },
        labels: { language: null },
        person: {
            persons: {
                items: [],
                itemsPerPage: 20,
                page: 1,
                search: '',
                sort: 'Surname asc',
                totalItems: 0
            },
        },
        rpaScriptEditor: {
            autoPlugOnDrop: true,
            commandsSections: [] as Array<Types_Rpa.RpaCommandsSection_TYPE>,
            documentEditorObject: createInitialState_documentEditor_object(),
            selectedCommand: undefined,
            selectedScript: undefined
        },
        signatureConfiguration: {
            activeEntity: undefined,
            loadedItems: {
                items: [],
                itemsPerPage: 10,
                page: 1,
                sort: 'Name asc',
                totalItems: 0,
            },
            searchText: ''
        },
        spinnigCounter: 0,
        taskList: {
            filters: {
                dynamics: [],
                // endDate and startDate must be undefined, they are now defined in config.json
                endDate: undefined,
                startDate: undefined,
                pageSize: -1,
                pageIndex: 1,
                search: '',
                sort: '',
                totalItems: 0
            },
            selectedTaskId: undefined,
            flatTasks: [],
            tasks: []
        },
        templateEditor: {
            selectedCompany: {} as Types.Company_TYPE,
            selectedTemplate: {} as Types.Template_Details_TYPE,
            companies: {
                items: [] as Array<Types.Company_TYPE>,
                page: 1,
                itemsPerPage: 20,
                totalItems: 0,
                sort: ''
            } as Types.Companies_Search_TYPE,
            companyTemplates: {
                items: [] as Array<Types.Template_TYPE>,
                page: 1,
                itemsPerPage: 20,
                totalItems: 0,
                sort: ''
            } as Types.Templates_Search_TYPE,
            documentEditorObject: createInitialState_documentEditor_object(),
            templateFields: [] as Array<any>,
            templateRules: [] as Array<any>
        } as Types.TemplateEditorState_TYPE,
        test: '---',
        toaster: { content: '', title: '', type: 'Information' } as Components.Toaster_Types.Toaster_Prop_Type,
        userHome: {
            commands: [],
            selectedCommand: undefined
        },
        userManagement: {
            userOnEdit: undefined,
            groupOnEdit: undefined,
            authorityOnEdit: undefined,
            languages: [],
            authorityTypes: [],
            contactTypes: [],
            selectedUserId: undefined,
            selectedGroupId: undefined,
            selectedAuthorityId: undefined,
            selectedUserContactId: undefined,
            users: {
                items: [],
                itemsPerPage: 10,
                page: 1,
                search: '',
                sort: 'Surname asc',
                totalItems: 0
            },
            userContacts: {
                items: [],
                itemsPerPage: 10,
                page: 1,
                search: '',
                sort: 'Id',
                totalItems: 0
            },
            userContactFilters: {
                pageIndex: 1,
                pageSize: 10,
                search: '',
                sort: 'Id'
            },
            userToAccessRights: {
                items: [],
                itemsPerPage: 10,
                page: 1,
                search: '',
                sort: 'Name',
                totalItems: 0
            },
            groups: {
                items: [],
                itemsPerPage: 10,
                page: 1,
                search: '',
                sort: 'Name',
                totalItems: 0
            },
            groupContacts: {
                items: [],
                itemsPerPage: 10,
                page: 1,
                search: '',
                sort: 'Id',
                totalItems: 0
            },
            groupContactFilters: {
                pageIndex: 1,
                pageSize: 10,
                search: '',
                sort: 'Id'
            },
            authorities: {
                items: [],
                itemsPerPage: 10,
                page: 1,
                search: '',
                sort: 'Name',
                totalItems: 0
            },
            authorityContacts: {
                items: [],
                itemsPerPage: 10,
                page: 1,
                search: '',
                sort: 'Id',
                totalItems: 0
            },
            authorityContactFilters: {
                pageIndex: 1,
                pageSize: 10,
                search: '',
                sort: 'Id'
            },
        }
    };
};

export {
    initialState,
    createAnchorCfg,
    creatFieldArea,
    createInstaceFieldAmend,
    createInitialState_documentEditor_object as createInitialState_designer_object,
    createValidatorCfg
};
