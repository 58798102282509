import { Components, Utils } from '@firedesktop/react-base';
import React, { useEffect, useState } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import _BaseActions from '../actions/_BaseActions';
import AuthenticationActions from '../actions/Authentication/AuthenticationActions';

import * as Types from '../Config/Types';

import * as AuthenticationPages from '../Pages/Authentication';
import Hubs from '../hubs/Hubs';

// Customer Routing 
const Routing185 = React.lazy(() => import('./_185Router'));
const RoutingFincantieriSIProposal = React.lazy(() => import('./_FincantieriSIProposalRouter'));
const RoutingJH = React.lazy(() => import('./_JHRouter'));


const UserHomePage = React.lazy(() => import('../Pages/UserHomePage/UserHomePage'));

// Accounting
const PassiveInvoicesPage = React.lazy(() => import('../Pages/Accounting/PassiveInvoicesPage/PassiveInvoicesPage'));
const ActiveInvoicesPage = React.lazy(() => import('../Pages/Accounting/ActiveInvoicesPage/ActiveInvoicesPage'));

// Alerts
const AlertsPage = React.lazy(() => import('../Pages/Alert/Configuration/Alerts/AlertsPage'));
const AlertsConfigurationPage = React.lazy(() => import('../Pages/Alert/Configuration/AlertsConfiguration/AlertsConfigurationPage'));
const AlertTriggers = React.lazy(() => import('../Pages/Alert/Configuration/AlertTriggers/AlertTriggersPage'));

// BaseConfiguration
const CompanyTypePage = React.lazy(() => import('../Pages/BaseConfiguration/CompanyTypePage/CompanyTypePage'));
const ContanctRolePage = React.lazy(() => import('../Pages/BaseConfiguration/ContanctRolePage/ContanctRolePage'));
const DocumentClassPage = React.lazy(() => import('../Pages/BaseConfiguration/DocumentClassPage/DocumentClassPage'));
const ContractTypePage = React.lazy(() => import('../Pages/BaseConfiguration/Contract/ContractTypePage/ContractTypePage'));
const ContractualFormsPage = React.lazy(() => import('../Pages/BaseConfiguration/Contract/ContractualFormsPage/ContractualFormsPage'));
const OwnershipTypePage = React.lazy(() => import('../Pages/BaseConfiguration/OwnershipTypePage/OwnershipTypePage'));
const ProductTypePage = React.lazy(() => import('../Pages/BaseConfiguration/Contract/ProductTypePage/ProductTypePage'));
const DataSegregationTagsPage = React.lazy(() => import('../Pages/PortalConfiguration/DataSegregationTag/DataSegregationTagsPage'));

// Code Generator
const MetaModelPage = React.lazy(() => import('../Pages/CodeGenerator/MetaModel/MetaModelPage'));

// Commands
const CommandsViewerPage = React.lazy(() => import('../Pages/Document/CommandsViewer/CommandsViewerPage'));

// Company
const BanksPage = React.lazy(() => import('../Pages/Company/BanksPage/BanksPage'));
const CompaniesPage = React.lazy(() => import('../Pages/Company/CompaniesPage/CompaniesPage'));
const CompanyDetailPage = React.lazy(() => import('../Pages/Company/CompanyDetail/CompanyDetailPage'));
const OperatorCompaniesPage = React.lazy(() => import('../Pages/Company/OperatorCompaniesPage/OperatorCompaniesPage'));

// Contract
const ContractsPage = React.lazy(() => import('../Pages/Contract/ContractsPage/ContractsPage'));
const ContractPage = React.lazy(() => import('../Pages/Contract/ContractPage/ContractPage'));
const ContractMinimalPage = React.lazy(() => import('../Pages/Contract/ContractMinimalPage/ContractMinimalPage'));

// Document
const DocumentsPage = React.lazy(() => import('../Pages/Document/DocumentsPage/DocumentsPage'));
const DocumentBundlesPage = React.lazy(() => import('../Pages/Document/DocumentBundlesModel/DocumentBundlesPage'));
const DocumentTamplatesPage = React.lazy(() => import('../Pages/Document/DocumentTemplatesPage/DocumentTemplatesPage'));
const EntitiesViewerPage = React.lazy(() => import('../Pages/Document/EntitiesViewerPage/EntitiesViewerPage'));

// Pages
const BucketsPage = React.lazy(() => import('../Pages/Buckets/BucketsPage/BucketsPage'));
const BundlesPage = React.lazy(() => import('../Pages/Company/BundlesPage/BundlesPage'));

// Person
const PersonsPage = React.lazy(() => import('../Pages/Person/PersonsPage'));

// Portal Configuration
const ApplicationDefinitionPage = React.lazy(() => import('../Pages/PortalConfiguration/Definition/Application/ApplicationDefinitionPage'));
const LicensesConfigurationPage = React.lazy(() => import('../Pages/PortalConfiguration/License/Configuration/LicensesPage'));
const LicensesParameterPage = React.lazy(() => import('../Pages/PortalConfiguration/License/Paramter/ParametersPage'));
const ModuleDefinitionPage = React.lazy(() => import('../Pages/PortalConfiguration/Definition/Module/ModuleDefinitionPage'));
const OAuthPage = React.lazy(() => import('../Pages/PortalConfiguration/OAuth/OAuthConfigurationPage'));
const SiteConfigurationPage = React.lazy(() => import('../Pages/PortalConfiguration/Site/SiteConfigurationPage'));
const TenantPage = React.lazy(() => import('../Pages/PortalConfiguration/Tenant/TenantPage'));

// Rec
const BundleEditorPage = React.lazy(() => import('../Pages/Rec/BundleEditorPage/BundleEditorPage'));
const TemplateEditorPage = React.lazy(() => import('../Pages/Rec/TemplateEditorPage/TemplateEditorPage'));

// Rpa
const RpaScriptEditorPage = React.lazy(() => import('../Pages/Rpa/RpaScriptEditorPage'));

// Signature
const ApprovalConfigurationPage = React.lazy(() => import('../Pages/Document/ApprovalConfigurationPage/ApprovalConfigurationPage'));

// Task
const TaskDefinitionPage = React.lazy(() => import('../Pages/Task/Configuration/TaskDefinition/TaskDefinitionPage'));
const TaskListPage = React.lazy(() => import('../Pages/Task/Runtime/TaskListPage/TaskListPage'));
const TaskPage = React.lazy(() => import('../Pages/Task/Runtime/TaskTable/TaskPage'));

// User Management
const UserManagementUserPage = React.lazy(() => import('../Pages/UserManagementPage/UserManagementUser/UserManagementUserPage'));
const UserManagementGroupPage = React.lazy(() => import('../Pages/UserManagementPage/UserManagementGroup/UserManagementGroupPage'));
const UserManagementAuthorityPage = React.lazy(() => import('../Pages/UserManagementPage/UserManagementAuthority/UserManagementAuthorityPage'));

// Workflow
const WorkflowEditorPage = React.lazy(() => import('../Pages/Workflow/WorkflowEditor/WorkflowEditorPage'));
const WorkflowStatePage = React.lazy(() => import('../Pages/Workflow/Configuration/State/WorkflowStatePage'));

// Test pages... delete at regime
const TestPage = React.lazy(() => import('../Pages/TestPages/TestPage'));
const TestFunctionPage = React.lazy(() => import('../Pages/TestPages/TestFunction/TestFunctionPage'));

function AppRouter() {
    const authentication = useSelector((state: Types.InitialState_TYPE) => state.authentication);
    const configuration = useSelector((state: Types.InitialState_TYPE) => state.configuration);
    const freeSpace = useSelector((state: Types.InitialState_TYPE) => state.freeSpace);
    const labels = useSelector((state: Types.InitialState_TYPE) => state.labels);
    const spinnigCounter = useSelector((state: Types.InitialState_TYPE) => state.spinnigCounter);
    const toaster = useSelector((state: Types.InitialState_TYPE) => state.toaster);

    const { loadConfigurationBE, updateAppStateGeneric } = _BaseActions();
    const { amIAuthenticated, checkLocalStorage } = AuthenticationActions();
    const amILoggedIn = amIAuthenticated();
    const language = authentication ? authentication.language : '';

    const [uniqueId] = useState(uuidv4());

    // Init Hubs
    Hubs(authentication, configuration);

    useEffect(() => {
        checkLocalStorage(configuration,);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configuration]);


    useEffect(() => {
        if (labels?.language)
            loadConfigurationBE(configuration, labels);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amILoggedIn, language, labels?.language, configuration]);

    const canIOpenPage = amILoggedIn && (configuration?.loadedBE ?? false);

    return (
        <>
            <Components.Toaster propertiesObject={toaster} />
            {/* If no labels loaded */}
            {configuration?.loaded && labels?.language ? <HashRouter>
                <React.Suspense fallback={<span>...</span>}>
                    <Routes>
                        {/* Accounting */}
                        <Route path="/Accounting">
                            <Route path="PassiveInvoices" element={canIOpenPage ? <PassiveInvoicesPage /> : <AuthenticationPages.LoginPage />} />
                            <Route path="ActiveInvoices" element={canIOpenPage ? <ActiveInvoicesPage /> : <AuthenticationPages.LoginPage />}>
                                <Route path=":invoiceId" element={canIOpenPage ? <ActiveInvoicesPage /> : <AuthenticationPages.LoginPage />} />
                            </Route>
                        </Route>

                        {/* Alerts */}
                        <Route path="/Alerts">
                            <Route path="Configuration">
                                <Route path="Alerts" element={canIOpenPage ? <AlertsPage /> : <AuthenticationPages.LoginPage />} />
                                <Route path="AlertsConfiguration" element={canIOpenPage ? <AlertsConfigurationPage /> : <AuthenticationPages.LoginPage />} />
                                <Route path="AlertTriggers" element={canIOpenPage ? <AlertTriggers /> : <AuthenticationPages.LoginPage />} />
                            </Route>
                        </Route>

                        {/* Authentication */}
                        <Route path="/ChangePassword" element={canIOpenPage ? <AuthenticationPages.ChangePasswordPage /> : <AuthenticationPages.LoginPage />} />
                        <Route path="/OAuthLogin" element={<AuthenticationPages.OAuthLogin />} />
                        <Route path="/ResetPasswordRequest" element={<AuthenticationPages.ResetPasswordRequestPage />} />
                        <Route path="/ResetPasswordWithToken/:token" element={<AuthenticationPages.ResetPasswordWithTokenPage />} />

                        {/* Base configurations */}
                        <Route path="/CompanyType" element={canIOpenPage ? <CompanyTypePage /> : <AuthenticationPages.LoginPage />} />
                        <Route path="/ContanctRole" element={canIOpenPage ? < ContanctRolePage /> : <AuthenticationPages.LoginPage />} />
                        <Route path="/DocumentClass" element={canIOpenPage ? < DocumentClassPage /> : <AuthenticationPages.LoginPage />} />
                        <Route path="/ContractType" element={canIOpenPage ? < ContractTypePage /> : <AuthenticationPages.LoginPage />} />
                        <Route path="/OwnershipType" element={canIOpenPage ? < OwnershipTypePage /> : <AuthenticationPages.LoginPage />} />
                        <Route path="/ProductType" element={canIOpenPage ? <ProductTypePage /> : <AuthenticationPages.LoginPage />} />
                        <Route path="/ContractualForms" element={canIOpenPage ? <ContractualFormsPage /> : <AuthenticationPages.LoginPage />} />
                        <Route path="/DataSegregationTags" element={canIOpenPage ? <DataSegregationTagsPage /> : <AuthenticationPages.LoginPage />} />

                        {/* Buckets */}
                        <Route path="/Buckets/:target" element={canIOpenPage ? <BucketsPage /> : <AuthenticationPages.LoginPage />} />

                        {/* Code Generator */}
                        <Route path="/CodeGenerator">
                            <Route path="MetaModel" element={canIOpenPage ? <MetaModelPage /> : <AuthenticationPages.LoginPage />} />
                        </Route>

                        {/* Company */}
                        <Route path="/Banks" element={canIOpenPage ? <BanksPage /> : <AuthenticationPages.LoginPage />} />
                        <Route path="/Companies" element={canIOpenPage ? <CompaniesPage /> : <AuthenticationPages.LoginPage />} />
                        <Route path="/CompanyDetail/:id" element={canIOpenPage ? <CompanyDetailPage /> : <AuthenticationPages.LoginPage />} />
                        <Route path="/CompanyBundles/:id" element={canIOpenPage ? <BundlesPage /> : <AuthenticationPages.LoginPage />} />
                        <Route path="/OperatorCompanies" element={canIOpenPage ? <OperatorCompaniesPage /> : <AuthenticationPages.LoginPage />} />

                        {/* Contracts */}
                        <Route path="/Contracts" element={canIOpenPage ? <ContractsPage /> : <AuthenticationPages.LoginPage />} />
                        <Route path="/Contract/:id" element={canIOpenPage ? <ContractPage /> : <AuthenticationPages.LoginPage />} />
                        <Route path="/ContractMinimal/:id" element={canIOpenPage ? <ContractMinimalPage /> : <AuthenticationPages.LoginPage />} />

                        {/* Documents */}
                        <Route path="/ApprovalConfiguration" element={canIOpenPage ? <ApprovalConfigurationPage /> : <AuthenticationPages.LoginPage />} />
                        <Route path="/Commands" element={canIOpenPage ? <CommandsViewerPage /> : <AuthenticationPages.LoginPage />} />
                        <Route path="/Documents" element={canIOpenPage ? <DocumentsPage /> : <AuthenticationPages.LoginPage />} />
                        <Route path="/DocumentTemplates" element={canIOpenPage ? <DocumentTamplatesPage /> : <AuthenticationPages.LoginPage />} />
                        <Route path="/DocumentBundles" element={canIOpenPage ? <DocumentBundlesPage /> : <AuthenticationPages.LoginPage />} />
                        <Route path="/Entities" element={canIOpenPage ? <EntitiesViewerPage /> : <AuthenticationPages.LoginPage />} />

                        {/* Pages */}
                        <Route path="/BundleEditor" element={canIOpenPage ? <BundleEditorPage /> : <AuthenticationPages.LoginPage />} />
                        <Route path="/RpaScriptEditor" element={canIOpenPage ? <RpaScriptEditorPage /> : <AuthenticationPages.LoginPage />} />
                        <Route path="/TestFunction" element={canIOpenPage ? <TestFunctionPage /> : <AuthenticationPages.LoginPage />} />
                        <Route path="/TemplateEditor" element={canIOpenPage ? <TemplateEditorPage /> : < AuthenticationPages.LoginPage />} />
                        <Route path="/Test" element={canIOpenPage ? <TestPage /> : <AuthenticationPages.LoginPage />} />
                        <Route path="/UserHome" element={canIOpenPage ? <UserHomePage /> : <AuthenticationPages.LoginPage />} />

                        { /* Persons */}
                        <Route path="/Persons" element={canIOpenPage ? <PersonsPage /> : <AuthenticationPages.LoginPage />} />

                        {/* Portal Configuration */}
                        <Route path="/PortalConfiguration">
                            <Route path="ApplicationDefinition" element={canIOpenPage ? <ApplicationDefinitionPage /> : <AuthenticationPages.LoginPage />} />
                            <Route path="License">
                                <Route path="Configuration" element={canIOpenPage ? <LicensesConfigurationPage /> : <AuthenticationPages.LoginPage />} />
                                <Route path="Parameter" element={canIOpenPage ? <LicensesParameterPage /> : <AuthenticationPages.LoginPage />} />
                            </Route>
                            <Route path="ModuleDefinition" element={canIOpenPage ? <ModuleDefinitionPage /> : <AuthenticationPages.LoginPage />} />
                            <Route path="OAuth" element={canIOpenPage ? <OAuthPage /> : <AuthenticationPages.LoginPage />} />
                            <Route path="Site" element={canIOpenPage ? <SiteConfigurationPage /> : <AuthenticationPages.LoginPage />} />
                            <Route path="Tenant" element={canIOpenPage ? <TenantPage /> : <AuthenticationPages.LoginPage />} />
                        </Route>

                        {/* Task */}
                        <Route path="/Task">
                            <Route path="Configuration">
                                <Route path="TaskDefinition" element={canIOpenPage ? <TaskDefinitionPage /> : <AuthenticationPages.LoginPage />} />
                            </Route>
                            <Route path="Runtime">
                                <Route path="TaskList" element={canIOpenPage ? <TaskListPage /> : <AuthenticationPages.LoginPage />}>
                                    <Route path=":id" element={canIOpenPage ? <TaskListPage /> : <AuthenticationPages.LoginPage />} />
                                </Route>
                                <Route path="TaskTable" element={canIOpenPage ? <TaskPage /> : <AuthenticationPages.LoginPage />} />
                            </Route>
                        </Route>
                        {/* We need this to support JH Mail */}
                        <Route path="TaskList" element={canIOpenPage ? <TaskListPage /> : <AuthenticationPages.LoginPage />}>
                            <Route path=":id" element={canIOpenPage ? <TaskListPage /> : <AuthenticationPages.LoginPage />} />
                        </Route>

                        {/* User Management */}
                        <Route path="/UserManUser" element={canIOpenPage ? <UserManagementUserPage /> : <AuthenticationPages.LoginPage />} />
                        <Route path="/UserManGroup" element={canIOpenPage ? <UserManagementGroupPage /> : <AuthenticationPages.LoginPage />} />
                        <Route path="/UserManAuthority" element={canIOpenPage ? <UserManagementAuthorityPage /> : <AuthenticationPages.LoginPage />} />

                        {/* Workflow */}
                        <Route path="/Workflow">
                            <Route path="Editor" element={canIOpenPage ? <WorkflowEditorPage /> : <AuthenticationPages.LoginPage />} />
                            <Route path="Configuration">
                                <Route path="State" element={canIOpenPage ? <WorkflowStatePage /> : <AuthenticationPages.LoginPage />} />
                            </Route>
                        </Route>

                        {/* Customization */}
                        {configuration.legge_185_90?.enabled ? <Route path="/185/*" element={<Routing185 canIOpenPage={canIOpenPage} configuration={configuration} language={language} freeSpace={freeSpace} />} /> : ''}
                        {(configuration as any).fincatieriSIProposal?.enabled ? <Route path="/FincantieriSIProposal/*" element={<RoutingFincantieriSIProposal canIOpenPage={canIOpenPage} configuration={configuration} language={language} freeSpace={freeSpace} />} /> : ''}
                        <Route path="/JH/*" element={<RoutingJH canIOpenPage={canIOpenPage} language={language} freeSpace={freeSpace} />} />

                        {/* NoMatch Pattern */}
                        <Route path="*" element={canIOpenPage ? <UserHomePage /> : <AuthenticationPages.LoginPage />} />
                    </Routes>
                </React.Suspense>
            </HashRouter> : `Loading labels and cofiguration... ${configuration?.loaded} - ${labels?.language}`}
            <Utils.Configuration.ConfigurationLoader updateAppState={updateAppStateGeneric} path={`configuration/${process.env.REACT_APP_TARGET}/config.json?${uniqueId}`} />
            <Utils.Labels.LanguageLoader updateAppState={updateAppStateGeneric} language={language} path={`labels/${process.env.REACT_APP_TARGET}`} />
            <Components.Spin spinning={spinnigCounter > 0} />
        </>
    );
}

export default AppRouter;
