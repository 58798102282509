import _ from 'lodash';
import { VerticalAlignTopOutlined } from '@ant-design/icons';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { FloatButton, Layout } from 'antd';
import { useEffect, useState } from 'react';
import { Dropdown, Nav } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

import HeaderBootstrap from './Header';
import useAntdPage, { SideBarItem_Type } from './useAntdPage';


type PropType = {
    children?: any;
};
export default function AntdPage({ children }: PropType) {
    const {
        collapsed,
        getLabel,
        items,
        onBrandClick
    } = useAntdPage();
    const { Content, Footer, Header, Sider } = Layout;
    const menu_backgroundColor = '#eeeeee';
    const menu_color = '#6C757D';
    const menu_child_backgroundColor = '#ffffff';
    const menu_child_color = '#6C757D';
    const iconDimension_Close = 22;
    const iconDimension_Open = 14;
    const fontSize = 14;
    // - header - footer
    const contentHeight = document.body.offsetHeight - 55 - 30;
    const layoutContentName = 'layoutContent';

    const [backTopVisibility, setBackTopVisibility] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            const contentElement = document.getElementById(layoutContentName);
            if (contentElement) {
                contentElement.onscroll = () => {
                    setBackTopVisibility(contentElement && contentElement.scrollTop > 100 ? true : false);
                };
            }
        }, 300);
        return () => {
            // Clean up the subscription
            const contentElement = document.getElementById(layoutContentName);
            if (contentElement)
                contentElement.onscroll = null;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const title = (icon: string | undefined, text: string, amIChild: boolean) => {
        if (collapsed && !amIChild)
            return (
                <TooltipComponent content={text ?? ''} position='TopCenter' style={{ display: 'inline-block', textAlign: 'center' }}>
                    <i className={icon} style={{ fontSize: iconDimension_Close, minWidth: iconDimension_Close }}></i> {' '}
                </TooltipComponent>);
        return (<>
            <i className={!_.isNil(icon) && !_.isEmpty(icon) ? icon : undefined}
                style={{ fontSize: iconDimension_Open, minWidth: 20 }}></i> {text ?? ''}
        </>);
    };

    const createItem = (item: SideBarItem_Type, index: number | string, amIChild: boolean) => {
        const paddingLeft = 9;
        const paddingBottom = 0;
        if (item.children?.length) {
            return (
                <Dropdown key={index} id={item.id ?? uuidv4()} drop='right' style={{ backgroundColor: amIChild ? menu_child_backgroundColor : menu_backgroundColor }}>
                    <Dropdown.Toggle split style={{
                        width: '100%',
                        textAlign: collapsed && !amIChild ? 'center' : 'left',
                        backgroundColor: amIChild ? menu_backgroundColor : menu_backgroundColor,
                        border: 'none',
                        color: amIChild ? menu_child_color : menu_color,
                        fontSize,
                        paddingBottom,
                        paddingLeft,

                    }}>
                        {title(item.icon, `${item.text}  `, amIChild)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ backgroundColor: menu_backgroundColor }}>
                        {item.children.map((child, childIndex) =>
                            <div key={childIndex}>
                                {child.children?.length
                                    ? createItem(child, childIndex, true)
                                    : child.text ? <>
                                        <Dropdown.Item key={childIndex} href={child.href} style={{
                                            backgroundColor: menu_backgroundColor,
                                            color: menu_color, fontSize,
                                            paddingBottom: 0,
                                            paddingLeft: 9,
                                        }}>
                                            <i className={!_.isNil(child.icon) && !_.isEmpty(child.icon) ? child.icon : undefined}
                                                style={{ fontSize, minWidth: 20, }}></i> {child.text}
                                        </Dropdown.Item>
                                    </> : <> </>}
                            </div>)}
                    </Dropdown.Menu>
                </Dropdown>);
        }
        else {
            return (<Nav.Link key={index} href={item.href ?? ''} style={{ color: menu_child_color, paddingLeft, paddingBottom }}>{title(item.icon, item.text, amIChild)}</Nav.Link>);
        }
    };

    return (
        <Layout>
            {backTopVisibility
                ? <FloatButton className='back-top' icon={<VerticalAlignTopOutlined />}
                    onClick={() => {
                        const contentElement = document.getElementById(layoutContentName);
                        if (contentElement)
                            contentElement.scrollTop = 0;
                    }}
                    tooltip={getLabel('common.tooltips.backTop')} />
                : ''}
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%', padding: 0 }}>
                <HeaderBootstrap onBrandClick={() => onBrandClick()} />
            </Header>
            <Layout style={{ marginTop: 60 }}>
                <Sider collapsible collapsed={collapsed} collapsedWidth={50} trigger={null} width={220}>
                    <Nav className="flex-column" style={{ fontSize }}>
                        {items?.length ? items.map((item, index) => createItem(item, index, false)) : ''}
                    </Nav>
                </Sider>
                <Layout style={{ height: contentHeight, marginTop: 5, overflowY: 'scroll' }} id={layoutContentName}>
                    <Content style={{ padding: 1, marginTop: 8 }}>
                        {children}
                    </Content>
                </Layout>
            </Layout>
            <Footer style={{ padding: 5 }}>
                <div className="container-fluid footer">
                    <div className="col-12 text-center">{`@${new Date().getFullYear()} FIREDESKTOP SRL`}</div>
                </div>
            </Footer>
        </Layout>);
}