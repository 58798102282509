import _BaseHub from './_BaseHub';

import * as Types from '../Config/Types';
import * as Types_Rec from '../Config/Types_Rec';

interface IActions {
    refreshInstaceStatus: (message: Types_Rec.Rec_Hub_instanceChangeStatus_TYPE) => void
}

export default class RecognitionHub extends _BaseHub {

    actions: IActions | undefined;


    public getMyName(): string {
        return 'Recognition';
    }

    public getMyHubUrl(configuration: Types.ConfigurationState_TYPE): string | undefined {
        return configuration.hubs?.recognition;
    }

    public setActions(actions: IActions) {
        this.actions = actions;
    }

    protected setHubConnectionActions(): void {
        if (this.connection) {
            console.log(`${this.getMyName()} - Hub Connected!, ConnectionId: ${this.connection.connectionId}`);


            this.connection.on('InstanceChangeStatus', message => {
                this.instanceChangeStatus(message);
            });
        }
    }

    // *********************************************************************************************************
    //      Actions
    // *********************************************************************************************************
    instanceChangeStatus = (message: Types_Rec.Rec_Hub_instanceChangeStatus_TYPE) => {
        if (this.actions)
            this.actions.refreshInstaceStatus(message);
    };
}
